import useApi from "../useApiRequest";
import { useMutation } from "react-query";

export const GetNewMessages = ()  => {
    const api = useApi();

    const mutate = async () => {
        const data  = await api.get(`/clientes/chats/new-messages`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getNewMessagesMutation: mutation.mutate,
    };
}