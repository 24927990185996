import useApi from "../../../hooks/api/useApiRequest";
import { useMutation } from "react-query";

export const GetEncuesta = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingEncuesta} params el tipo {@link MutatingEncuesta} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async () => {
        const data  = await api.get(`/clientes/encuestas/1`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getEncuestaMutation: mutation.mutate,
    };
}