import useApi from "../../../hooks/api/useApiRequest";
import { useMutation } from "react-query";

export const NewTicket = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingNewTicket} params el tipo {@link MutatingNewTicket} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (params) => {
        const formData = new FormData();
        formData.append('empresa', params.empresa);
        formData.append('licencia', params.licencia);
        formData.append('sintomas', params.problematica);
        formData.append('observaciones', params.observaciones);
        formData.append('version', params.version);
        params.files.forEach(file => {
            formData.append('files[]', file.originFileObj !== undefined ? file.originFileObj : file, file.name );
        })
        const data  = await api.post(`/clientes/tickets`, formData, {
            dataType: "formData",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        newTicketMutation: mutation.mutate,
    };
}