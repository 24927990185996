import React from "react";
import { CloseCircleOutlined, HomeOutlined, ShopOutlined } from "@ant-design/icons";
import { Button, Modal, Result, Typography  } from "antd";
import './styles.css'

const { Paragraph, Text } = Typography;


const ModalResult = ({isOpenResult, isSuccess, paquete, idFolio}) => {


    const goBuyAgai = () =>{
        window.location.href=`/paquetes`
    }
    const goHome = () =>{
        window.location.href=`/ticketsAdministracion`
    }


    return(
        <Modal open={isOpenResult} footer={null}  closable={false} className="modal_result_pay" >
            {isSuccess ? (
                <Result
                status="success"
                title="¡Su compra ha sido exitosa!"
                subTitle={`Número de orden: ${idFolio} - Paquete: ${paquete.nombre}`}
                extra={[
                    <Button type="primary" key="buy_again" style={{backgroundColor: '#44ADAB', marginTop:5, padding:5}} onClick={goBuyAgai}>
                        Ir a tienda <ShopOutlined />
                    </Button>,
                    <Button key="go_invoices" className="btn_go_home" style={{border:'1px solid #44ADAB', color:'#44ADAB', marginTop:5, padding:5}} onClick={goHome}>
                        Ir al inicio <HomeOutlined />
                    </Button>,
                ]}
                style={{width:'100%'}}
                className="result_pay"
                />
            ):(
                <Result
                status="error"
                title="Error de Compra"
                subTitle="Hubo un error al procesar el pago, por favor intente de nuevo, si el error persiste contacte a un asesor."
                extra={[
                    <Button type="primary" key="buy_again" style={{backgroundColor: '#44ADAB', marginTop:5, padding:5}} onClick={goBuyAgai}>
                        Volver a intentar <ShopOutlined />
                    </Button>,
                    <Button key="go_invoices" className="btn_go_home" style={{border:'1px solid #44ADAB', color:'#44ADAB', marginTop:5, padding:5}} onClick={goHome}>
                        Ir al inicio <HomeOutlined />
                    </Button>
                ]}
                >
                </Result>
            )}
        </Modal>
    )
}

export default ModalResult;