import React, {useState, useRef, useEffect} from "react";
import ChatMessage from "../../../../components/Chats/ChatMessage";
import { useChat } from "../../../../hooks/api/chat/useChat";
import { Button, Input, Spin, Row, Col, message } from "antd";
import {SendOutlined} from '@ant-design/icons';
import { createUseStyles } from "react-jss";
import { chatStyles } from "./styles";
import { setScrollToBottom } from "../../../../helpers/scroll";
import './styles.css'

const useStyles = createUseStyles(chatStyles);

const Conversation = ({ conv_id }) => {

    const styles = useStyles();
    const [loadingMoreMessages,] = useState(false);
    const loader = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const {
        messages,
        chatNamespace,
        messagesPage,
        sendMessage,
        getMessagesPage,
        setChat
    } = useChat();

    const handleClickSendMessage = () => {
        if (inputValue !== "") {
            sendMessage(inputValue).then(() => {
                setInputValue("");
                setTimeout(() => {
                    setScrollToBottom(`#chat-${conv_id}`)
                }, 100);
            }).catch((err) => {
                message.error(err);
                setInputValue("");
            })
        }
    }

    const handleKeyDownMessage = (e) => {
        if (e.code === "Enter" && inputValue !== "") {
            sendMessage(inputValue).then(() => {
                setInputValue("");
                setTimeout(() => {
                    setScrollToBottom(`#chat-${conv_id}`)
                }, 100);
            }).catch((err) => {
                message.error(err);
                setInputValue("");
            })
        }
    }

    const handleChangeInput = (e) => {
        setInputValue(e.target.value)
    }

    const initInfiniteScrolling = () => {
        const loadNewMessagesObserver = new IntersectionObserver((entries) => {
            const lastMessage = entries[0];
            if (lastMessage.isIntersecting) {
                getMessagesPage(messagesPage + 1);
            }
        }, { threshold: 1 });
        loadNewMessagesObserver.observe(loader.current)
    }

    useEffect(() => {
        if (conv_id) {
            setChat(conv_id, () => {
                setTimeout(() => {
                    setScrollToBottom(`#chat-${conv_id}`)
                }, 100);
            });
        }
    }, [conv_id]);

    useEffect(() => {
        if (conv_id) {
            setScrollToBottom(`#chat-${conv_id}`)
        }
    }, [messages]);


    return(
        <div className={styles.chat}>
            <div
                className='conversation-container'
                id={`chat-${conv_id}`}
            >
                <div ref={loader} style={{ width: "100%", height: 30, display: "flex", justifyContent: "center", padding: "30 0px" }} >
                    {
                        loadingMoreMessages && <Spin />
                    }
                </div>
                {
                    messages.map((message, iMessage) =>
                        <ChatMessage
                            text={message.mens_detalle}
                            key={`${iMessage}_${message.mens_fecha}`}
                            chatId={conv_id}
                            user={message.usu_id}
                            date={message.mens_fecha}
                            type={message.type}
                        />)
                }
            </div>
            <Row>
                <Col span={21} style={{padding: '0px 10px'}}>
                    <Input  placeholder="Mensaje" className="inputChat" 
                        value={inputValue} onChange={handleChangeInput} 
                        onKeyDown={handleKeyDownMessage} autoFocus/>
                </Col>
                <Col span={3} style={{padding: '0px 3px'}}>
                    <Button
                        type="primary"
                        shape='circle'
                        icon={<SendOutlined style={{ fontSize: 14 }} />}
                        onClick={handleClickSendMessage}
                        style={{backgroundColor: '#44adab', display:'flex', justifyContent: 'center', alignItems:'center'}}
                    />
                </Col>
            </Row>
        </div>
    )

}

export default Conversation;