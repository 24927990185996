import useApi from "../useApiRequest";
import { useMutation } from "react-query";

export const ChangePassword = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingChangePassword} params el tipo {@link MutatingChangePassword} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (params) => {
        const data  = await api.put(`/clientes/change-password`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        changePasswordMutation: mutation.mutate,
    };
}