const initialState = {
    apiHost: 'http://192.168.100.22:3001/api', // Valor inicial de apiHost
  };
  
  const appReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_API_HOST':
        return { ...state, apiHost: action.payload };
      default:
        return state;
    }
  };
  
  export default appReducer;