import React, {useEffect, useState} from "react";
import { Layout, Col, Row, message } from "antd";
import CardImg from "./components/cardImg/cardImg";
import CardTabs from "./components/cardTabs/cardTabs";
import { GetLicencias } from "../../hooks/api/licencias/getLicencias";
import { GetEmpresas } from "../../hooks/api/empresas/getEmpresas";
import './styles.css'

const { Content } = Layout;

const Profile = () => {

    const {getLicenciasMutation} = GetLicencias();
    const {getEmpresasMutation} = GetEmpresas();
    const dataUser = JSON.parse(sessionStorage.getItem('userKronox'))
    const [user, setUser] = useState(dataUser);
    const [company, setCompany] = useState(undefined);
    const [systems, setSystems] = useState(undefined);
    

    useEffect(() => {
        const token = sessionStorage.getItem('tokenKronox');
        if (!token) {
            window.location.href = '/login';
        }else{
            if(dataUser.usuarios_empresas){
                if(dataUser.usuarios_empresas.length > 0){
                    let index = dataUser.usuarios_empresas.findIndex(value => value.use_principal===true)
                    setCompany(dataUser.usuarios_empresas[index === -1 ? 0 : index])
                    getLicenciasMutation(dataUser.usuarios_empresas[index === -1 ? 0 : index].emp_id,
                        {
                            onSuccess: (data) => {
                                setSystems(data)
                            },
                            onError: (error) =>{
                                message.error(error)
                            }
                    })
                }else{
                    setSystems(null)
                    setCompany(null)
                }
            }else{
                setSystems(null)
                setCompany(null)
            }
        }
    }, [])

    useEffect(() => {
        
        const intervalId = setInterval(getEmpresa, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const getEmpresa = () => {
        getEmpresasMutation({},{
            onSuccess: (data) => {
                if(data.length > 0){
                    const empresaData = data.find((objeto) => {
                        const usuarioPrincipal = objeto.usuarios_empresas.find((usuario) => usuario.use_principal === true);
                        return usuarioPrincipal !== undefined;
                    });
                    if(empresaData !== undefined){
                        setCompany(empresaData.usuarios_empresas[0].empresa)
                    }else{setCompany(null)}
                }else{
                    console.log('no empresas')
                }
            },
            onError: (error) => {
                message.error(error)
            }
        })
    }

    return(
        <Content>
            <Row>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{padding:'1.5%'}}>
                    <CardImg    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{padding:'1.5%'}}>
                    {user !== undefined && company !== undefined ? (<CardTabs  user={user}  company={company} systems={systems}/>): null }
                </Col>
            </Row>
        </Content>
    )
}

export default Profile;