import React from "react";
import {Form, Row, Col, Input, message } from 'antd';
import { motion } from "framer-motion";
import { PostSendCodeRegister } from "../../../../hooks/api/register/sendCode";
import { CheckEmail } from "../../../../hooks/api/register/checkEmail";
import fixtures from "../../fixtures";
import './styles.css'

const FormData = ({formData, setFormData, setCodeSend})=>{

    const {postSendCodeRegisterMutation} = PostSendCodeRegister();
    const {mutateEmail} = CheckEmail();

    //VALIDACIONES
    const validateEmail = (_, value) => {
        // Expresión regular para validar el formato de correo electrónico
        const emailRegex = /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;
        
        // Verificar si el valor cumple con el formato de correo electrónico
        if (value && !emailRegex.test(value)) {
            return Promise.reject('Ingresa un correo electrónico válido');
        }
        
        return Promise.resolve();
    };
    const validatePhoneNumber = (_, value) => {
        // Expresión regular para validar números de teléfono (solo dígitos, mínimo 10 y máximo 12 dígitos)
        const phoneNumberRegex = /^[0-9]{10,13}$/;
        
        // Verificar si el valor cumple con el formato de número de teléfono
        if (value && !phoneNumberRegex.test(value)) {
            return Promise.reject('Ingresa un número  válido');
        }
        
        return Promise.resolve();
    };
    const handleKeyDown = (e) => {
        const { key, keyCode } = e;
    
        // Verificar si la tecla presionada es un número
        if (!/[0-9]/.test(key) && (key !== 'Backspace' || keyCode !== 8)) {
          e.preventDefault(); // Prevenir la entrada de caracteres no numéricos
        }
    };

    //METODO FINALIZAR FORM
    const onFinish = (values) => {
        let dataSendCode = {
            mail: values.email,
            name: values.name
        }
        mutateEmail({
            "email": values.email,
        },
            {
                onSuccess: (data)=>{
                    if(!data.success){
                        postSendCodeRegisterMutation(dataSendCode,
                            {
                                onSuccess: (data) => {
                                    if(data.success){
                                        setFormData(values);
                                        setCodeSend(true);
                                    }else{
                                        message.error(data.mensaje);
                                    }  
                                    
                                },
                                onError: (error) =>{
                                    message.error(error)
                                }
                            }
                        )
                    }else{
                        message.error(data.mensaje)
                    }
                },
                onError: (error) =>{
                    message.error(error)
                }
            })
        }



    return(
        <Form  onFinish={onFinish} className='formRegister' initialValues={formData}>
                <Row>
                    <Col span={24}>
                        <Form.Item name='name' rules={[{required:true, message: 'Ingrese un nombre'}]}>
                            <Input className='inputFormRegister' placeholder='Nombre(s)'/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <Form.Item name='apellidoP' rules={[{required:true, message: 'Ingrese un apellido paterno'}]}>
                            <Input className='inputFormRegister' placeholder='Apellido Paterno'/>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item name='apellidoM' rules={[{required:true, message: 'Ingrese un apellido materno'}]}>
                            <Input className='inputFormRegister' placeholder='Apellido Materno'/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name='email' rules={[{required:true, message: 'Ingrese un correo'}, { validator: validateEmail }]}>
                            <Input className='inputFormRegister' placeholder='Correo electrónico'/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name='number' rules={[{required:true, message: 'Ingrese un número de teléfono'},{ validator: validatePhoneNumber }]}>
                            <Input className='inputFormRegister' minLength={10} maxLength={13} onKeyDown={handleKeyDown} placeholder='Teléfono'/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <p className='textTC'>Al registrarte aceptas los</p> <a href={require('../../../../assets/pdfs/term&Cond.pdf')} target="_blank"  rel="noopener noreferrer" className='tC'>Términos y Condiciones</a>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <motion.button whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                            type='submit'  className='btn-Primary btnRegister'>
                            Continuar
                        </motion.button>
                    </Col>
                </Row>
        </Form>
    )

}

export default FormData;