import useApi from "../../../hooks/api/useApiRequest";
import { useMutation } from "react-query";

export const PostRecursoIncidencia = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingNewTicket} params el tipo {@link MutatingNewTicket} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (params) => {
        const formData = new FormData();
        params.files.forEach(file => {
            formData.append('files[]', file.originFileObj !== undefined ? file.originFileObj : file, file.name );
        })
        const data  = await api.post(`/tickets_incidencias/${params.id}/recursos`, formData, {
            dataType: "formData",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        postRecursoIncidencia: mutation.mutate,
    };
}