import useApi from "../useApiRequest";
import { useMutation } from "react-query";

/**
 * 
 * @typedef MutatingCompany
 * @property {String} mutationType -  tipo de accion que se va a realizar
 */

export const PostCompany = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingCompany} params el tipo {@link MutatingCompany} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (params) => {
        const data  = await api.put(`/clientes/empresas/${params.id_empresa}`, params.data, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        postCompany: mutation.mutate,
    };
}