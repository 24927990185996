import React, { useEffect, useState } from "react";
import {Row, Col, Input, Form, message} from "antd";
import { GetDomicilioEmpresas } from "../../../../../../hooks/api/empresas/getDomicilio";
import { PostCompany } from "../../../../../../hooks/api/empresas/updateDomicilio";
import { PostAdress } from "../../../../../../hooks/api/empresas/updateAdress";
import { CrearDomicilio } from "../../../../../../hooks/api/empresas/createDomicilio";
import { motion } from "framer-motion";
import fixtures from '../../../../fixtures'
import './styles.css'

const TabCompany = ({company}) => {

    const [form] = Form.useForm();

    const {getEmpresasDomicilioMutation} = GetDomicilioEmpresas();
    const {postAdress} = PostAdress();
    const {postCompany} = PostCompany();
    const {crearDomicilioMutation} = CrearDomicilio();

    const [companyData, setCompanyData] = useState(null);
    const [haveAdress, setHaveAdress] = useState(false);

    useEffect(() => {
        getDataCompanie();
    },[])

    const getDataCompanie = () => {
        let companies = JSON.parse(sessionStorage.getItem('userKronox')).usuarios_empresas;
        let main_company = companies.some(company => company.use_principal) ? companies.find(company => company.use_principal).empresa : companies[0].empresa;
        if(main_company !== null && main_company !== undefined){
            getEmpresasDomicilioMutation(main_company.emp_id,{
                onSuccess: (data) => {
                    if(data !== undefined){
                        if(data.length > 0){
                            setHaveAdress(true)
                            const companyAdress = data.some(value => value.dom_principal) ? data.find(value => value.dom_principal) : data[0]
                            const newData = {
                                id: companyAdress.dom_id,
                                emp_razonsocial: main_company.emp_razonsocial,
                                emp_nombrecomercial : main_company.emp_nombrecomercial,
                                emp_rfc : main_company.emp_rfc,
                                emp_id:main_company.emp_id,
                                dom_calle: companyAdress.dom_calle,
                                dom_exterior: companyAdress.dom_exterior,
                                dom_interior: companyAdress.dom_interior,
                                dom_colonia: companyAdress.dom_colonia,
                                dom_municipio: companyAdress.dom_municipio,
                                dom_localidad: companyAdress.dom_localidad,
                                dom_estado: companyAdress.dom_estado,
                                dom_cp: companyAdress.dom_cp,
                                dom_pais: companyAdress.dom_pais
                            }
                            setCompanyData(newData)
                        }else{
                            const newData = {
                                emp_razonsocial: main_company.emp_razonsocial,
                                emp_nombrecomercial : main_company.emp_nombrecomercial,
                                emp_rfc : main_company.emp_rfc,
                                emp_id:main_company.emp_id,
                            }
                            setCompanyData(newData)
                            setHaveAdress(false)
                        }
                    }else{
                        const newData = {
                            emp_razonsocial: main_company.emp_razonsocial,
                            emp_nombrecomercial : main_company.emp_nombrecomercial,
                            emp_rfc : main_company.emp_rfc,
                            emp_id:main_company.emp_id,
                        }
                        setCompanyData(newData)
                    }
                },
                onError: (error) =>{
                    message.error(error)
                    const newData = {
                        emp_razonsocial: company.emp_razonsocial,
                        emp_nombrecomercial : company.emp_nombrecomercial,
                        emp_rfc : company.emp_rfc,
                    }
                    setCompanyData(newData)
                }
            })
        }else{
            const newData = {
                id: '',
                emp_razonsocial: '',
                emp_nombrecomercial : '',
                emp_rfc : '',
                dom_calle: '',
                dom_exterior: '',
                dom_interior: '',
                dom_colonia: '',
                dom_municipio: '',
                dom_localidad: '',
                dom_estado: '',
                dom_cp: '',
                dom_pais: ''
            }
            setCompanyData(newData)
        }
    }

    //FORMATOS DE INPUTS

    const formCP = (fieldName, e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[^0-9/]/g, '');
        if(newValue.length>5){
            newValue = newValue.substring(0, 5);
            const updatedValues = { [fieldName]: newValue };
            form.setFieldsValue(updatedValues);
        }
        
    };
    const formText = (fieldName, e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[^a-zA-Z]/g, '').toUpperCase();;
    
        // Usar el nombre del campo dinámicamente
        const updatedValues = { [fieldName]: newValue };
        form.setFieldsValue(updatedValues);
    };
    const formTextUpper = (fieldName, e) => {
        let newValue = e.target.value;
        newValue = newValue.toUpperCase();;
    
        // Usar el nombre del campo dinámicamente
        const updatedValues = { [fieldName]: newValue };
        form.setFieldsValue(updatedValues);
    };
    
    //VALIDACIONES

    const validatePostalCode = (_, value) => {
        if (value && value.length !== 5 ) {
            return Promise.reject('El código postal es incorrecto');
        }
        return Promise.resolve();
    };
    const validateRFC = (_, value) => {
        if (value && value.length < 10 ) {
            return Promise.reject('RFC incompleto');
        }
        else if (value && value.length > 13 ){
            return Promise.reject('RFC incorrecto');
        }
        else if (value  && value.length > 9 && value.length < 12){
            return Promise.reject('Complete la homoclave');
        }
        return Promise.resolve();
    };

    const saveCompany = (values) => {
        const dataCompany = {
            id_empresa: companyData.emp_id,
            data: {
                emp_razonsocial: values.emp_razonsocial,
                emp_rfc: values.emp_rfc,
                emp_nombrecomercial: values.emp_nombrecomercial,
            }
        }
        const adress = {
            id_empresa: companyData.emp_id,
            id_adress: companyData.id,
            data : {
                dom_calle: values.dom_calle,
                dom_exterior: values.dom_exterior,
                dom_interior: values.dom_interior,
                dom_colonia: values.dom_colonia,
                dom_cp: values.dom_cp,
                dom_localidad: values.dom_localidad,
                dom_municipio: values.dom_municipio,
                dom_estado: values.dom_estado,
                dom_pais: values.dom_pais,
            }
        }

        
        postCompany(dataCompany, {
            onSuccess: (data) => {
                message.success(data.mensaje)
                if(haveAdress){
                    postAdress(adress, {
                        onSuccess: (data) => {
                            message.success(data.mensaje)
                            getDataCompanie();
                        },
                        onError: (error) =>{
                            message.error(error)
                        }
                    })
                }else{
                    crearDomicilioMutation(adress, {
                        onSuccess: (data) => {
                            message.success(data.mensaje)
                            getDataCompanie();
                        },
                        onError: (error) =>{
                            message.error(error)
                        }
                    })
                }
                
            },
            onError: (error) =>{
                message.error(error)
            }
        })
        
    }

    return(
        <>
        {companyData !== null && (
            <Form layout="vertical" onFinish={saveCompany} form={form} initialValues={companyData}>
            <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='emp_razonsocial' label='Razón Social' rules={[{ required: true, message: 'Ingrese una razón social' }]}>
                        <Input className="inputTabPersonal" placeholder="Razón social" onChange={(e) => formTextUpper('emp_razonsocial', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='emp_nombrecomercial' label='Nombre Comercial' rules={[{ required: true, message: 'Ingrese un nombre comercial' }]}>
                        <Input className="inputTabPersonal" placeholder="Nombre Comercial" onChange={(e) => formTextUpper('emp_nombrecomercial', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='emp_rfc' label='RFC' rules={[{ required: true, message: 'Ingrese un RFC' }, {validator: validateRFC}]}>
                        <Input className="inputTabPersonal" placeholder="RFC" onChange={(e) => formTextUpper('emp_rfc', e)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='dom_calle' label='Calle' rules={[{ required: true, message: 'Ingrese una calle' }]}>
                        <Input className="inputTabPersonal" placeholder="Calle"/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='dom_exterior' label='N° Exterior' rules={[{ required: true, message: 'Ingrese un n° exterior' }]}>
                        <Input className="inputTabPersonal" placeholder="N° Exterior" onChange={(e) => formTextUpper('dom_exterior', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='dom_interior' label='N° Interior'>
                        <Input className="inputTabPersonal" placeholder="N° Interior" onChange={(e) => formTextUpper('dom_interior', e)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='dom_colonia' label='Colonia' rules={[{ required: true, message: 'Ingrese una colonia' }]}>
                        <Input className="inputTabPersonal" placeholder="Colonia" onChange={(e) => formTextUpper('dom_colonia', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='dom_municipio' label='Municipio' rules={[{ required: true, message: 'Ingrese un municipio' }]}>
                        <Input className="inputTabPersonal" placeholder="Municipio" onChange={(e) => formText('dom_municipio', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='dom_localidad' label='Localidad' rules={[{ required: true, message: 'Ingrese un localidad' }]}>
                        <Input className="inputTabPersonal" placeholder="Localidad" onChange={(e) => formText('dom_localidad', e)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='dom_estado' label='Estado' rules={[{ required: true, message: 'Ingrese una estado' }]}>
                        <Input className="inputTabPersonal" placeholder="Estado" onChange={(e) => formText('dom_estado', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='dom_cp' label='Código Postal' rules={[{ required: true, message: 'Ingrese un código postal' }, {validator: validatePostalCode}]}>
                        <Input className="inputTabPersonal" placeholder="Código Postal" onChange={(e) => formCP('dom_cp', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='dom_pais' label='País' rules={[{ required: true, message: 'Ingrese un país' }]}>
                        <Input className="inputTabPersonal" placeholder="País" onChange={(e) => formText('dom_cp', e)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginBottom:10}}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{padding:'2%'}}>
                    <motion.button whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                            type='submit'  className='btn-Primary btnSendTicket'>
                            Enviar
                    </motion.button>
                </Col>
            </Row>
            </Form>
        )}
        </>
    )
}

export default TabCompany;