import { DateTime } from "luxon";

export const calculaTiempoTranscurridoServicio = (incidencias) => {

    let statusTiempoDetenido = [1, 3, 4, 5, 6, 7]; // estatus del ticket que detienen el tiempo
    let iIncidenciaActual = 0;
    let ultimaIncidenciaAfectaTiempo;
    // el tiempo se mide en segundos
    let segundosTranscurridos = 0;

    let incidenciaActual = incidencias[iIncidenciaActual];
    // se iteran las incidencias para empezar a calcular el tiempo
    while (incidenciaActual) { // se valida que la incidencia a la que se está accediendo existe
        /*
            -> se busca una incidencia que permita  contar el tiempo
            -> las incidencias que permiten contar el tiempo son aquellas que
                1. no contienen un status nulo
                2. que contengan un status 
                    [2] = Ticket en Seguimiento
        */
        if (incidenciaActual.stat_id === 2) { // se valida que la incidencia tenga un status 2
            // se busca la siguiente incidencia que detenga el tiempo
            let iIncidenciaSiguiente = iIncidenciaActual + 1;
            let incidenciaSiguiente;
            do {
                incidenciaSiguiente = incidencias[iIncidenciaSiguiente];
                iIncidenciaSiguiente++;
            } while (incidenciaSiguiente && !statusTiempoDetenido.includes(incidenciaSiguiente.stat_id));

            // let fechaIncidenciaActual =  new Date(incidenciaActual.tici_fecha);
            let fechaIncidenciaActual =  DateTime.fromISO(incidenciaActual.tici_fecha, { zone: "utc" });
            if (incidenciaSiguiente) { //se valida si se encuentra una incidencia siguiente válida
                // se calcula cuanto tiempo ha transcurrido entre incidencias
                // let fechaIncidenciaSiguiente = new Date(incidenciaSiguiente.tici_fecha);
                let fechaIncidenciaSiguiente = DateTime.fromISO(incidenciaSiguiente.tici_fecha, { zone: "utc" });
                // segundosTranscurridos += Math.floor(fechaIncidenciaSiguiente / 1000) - Math.floor(fechaIncidenciaActual / 1000);
                segundosTranscurridos += fechaIncidenciaSiguiente.toUnixInteger() - fechaIncidenciaActual.toUnixInteger();

                ultimaIncidenciaAfectaTiempo = incidenciaSiguiente
                // se setea la incidencia actual en la posicion de la ultima encontrada
                iIncidenciaActual = iIncidenciaSiguiente - 1;
            } else {
                //si no se encontró una incidencia siguiente , se toma el rango del tiempo en base a la fecha y hora actual
                let fechaActual = DateTime.now().toUTC();

                // segundosTranscurridos += Math.floor(fechaActual / 1000) - Math.floor(fechaIncidenciaActual / 1000);
                segundosTranscurridos +=  fechaActual.toUnixInteger() - fechaIncidenciaActual.toUnixInteger();
                ultimaIncidenciaAfectaTiempo = incidenciaActual
                break;
            }
        } else {
            if (incidenciaActual?.stat_id) {
                ultimaIncidenciaAfectaTiempo = incidenciaActual
            }
        }
        iIncidenciaActual++;
        incidenciaActual = incidencias[iIncidenciaActual];
    }

    return {
        segundosTranscurridosServicio: segundosTranscurridos,
        ultimaIncidenciaAfectaTiempoServicio: ultimaIncidenciaAfectaTiempo
    };
}