import React, {useState, useEffect} from 'react';
import { Layout, Row, Col } from 'antd';
import './styles.css'
import ForgetPass from './forgetPass';


const IndexForgetPass = () => {

    const [numerCard, setNumerCard] = useState(1)

    useEffect(() => {

        //LIMPIEZA DE TOKEN Y USUARIO AL DESLOGEAR
        const token = sessionStorage.getItem('tokenKronox');
        if (token) {
            window.location.href = '/ticketsAdministracion';
        }
        const id_paquete = sessionStorage.getItem('id_paquete');
        if (id_paquete) {
            sessionStorage.removeItem('id_paquete')
        }

    }, [])

    const changeCard = (id) => {
        setNumerCard(id)
    }


    return(
        <Layout className='layoutLogin'>
            <Row style={{width:'100%'}}>
                <Col xs={{ span:18, offset:3 }} sm={{ span:15, offset:5 }} md={{ span:14, offset:5 }} lg={{ span:7, offset:9 }}>
                    <ForgetPass  goLogin={changeCard}/>
                </Col>
            </Row>
        </Layout>
    )
}


export default IndexForgetPass;