import React, {useEffect, useState} from "react";
import {Layout, Row, Col, Table, Input, message } from "antd";
import { motion } from "framer-motion";
import { GetTickets } from "../../../hooks/api/tickets/getTickets";
import moment from "moment/moment";
import fixtures from "./fixtures";
import './style.css'

const { Content } = Layout;
const { Search } = Input;

const TablaTicket = () => {

    const {getTicketsMutation} = GetTickets();

    const token = sessionStorage.getItem('tokenKronox');
    const [columns, setColumns] = useState(fixtures().colums);
    const [dataSource, setDataSource] = useState(null);
    const [dataFiltered, setDataFiltered] = useState(null);
    const [data, setData] = useState(null)
    const [searchText, setSearchText] = useState('');
    useEffect(()=> {
        if (!token) {
            window.location.href = '/login';
        }
        else{
            getTicketsMutation({},
                {
                    onSuccess: (data) => {
                        if(data.length !== 0){
                            const newData = data.map((item, index) => {
                                const findAsesor = item.agentes.find(value => value.tica_usumodificaticket === true);
                                return{
                                    key: index,
                                    id: item.tick_id,
                                    folio: item.tick_clave,
                                    fecha: moment(item.tick_fecharegistro).format("DD/MM/YYYY hh:mm A"),
                                    status: item.status.stat_nombre,
                                    system: item.licencia.sistema.sis_nombre,
                                    problem: item.tick_sintomas,
                                    do: item.creador.usu_nombres,
                                    asesor: findAsesor ? findAsesor.agente.usu_nombres : ''
                                }
                            });
                            setDataSource(newData)
                            setDataFiltered(newData)
                            setData(newData)
                        }
                    },
                    onError: () =>{
                        message.error("error")
                    }
                })
        }
    }, [token])

    const handleRowClick = (record) => {
        const isClose = record.status.toLowerCase().includes('cerrado');
        window.location.href=`/ticket_info?id=${record.id}&folio=${record.folio}&close=${isClose}`
    };

    const handleSearch = (value) => {
        const filtered = dataSource.filter(item =>
          Object.values(item).some(val => val.toString().toLowerCase().includes(value.toLowerCase())
          )
        );
        setDataFiltered(filtered);
    };

    return(
        <>
            <Content style={{padding:'2%'}}>
                <motion.div className="cardTableTicket" 
                initial={fixtures().cardInitial}
                animate={fixtures().cardAnimated}
                transition={fixtures().cardTransition}
                >
                    <Row style={{marginBottom:10}}>
                        <Col span={18} className="colTitleTableTickets">
                            <span className="titleTableTickets">Mis Tickets</span>
                        </Col>
                    </Row>
                    <Row style={{marginBottom:20}}>
                        <Col xs={{offset:12, span:12}} sm={{offset:12, span:12}} md={{offset:16, span:8}} lg={{offset:18, span:6}} xl={{offset:18, span:6}} className="colSearch">
                            <Input  placeholder="Buscar"  className="search" onChange={e => handleSearch(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{height:''}}>
                            <Table 
                                dataSource={dataFiltered} 
                                columns={columns}  
                                scroll={{x:true}} 
                                pagination={{pageSize:4}}  
                                onRow={(record) => ({
                                    onClick: () => {
                                        handleRowClick(record);
                                    },
                                })}
                                className="tableData"/>
                        </Col>
                    </Row>
                </motion.div>
            </Content>
        </>
    )
}
export default TablaTicket; 