import { blue } from "@mui/material/colors";

export const chatContainerStyles = theme => ({
    chatContainer: {
        minHeight: "inherit",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#fff",

    },
    chatListContainer: {
        width: "100%",
        minHeight: "inherit",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderRight: "1px solid #ddd",
        flex: 0.3,
        border: 'none !important'
    },
    chatMessagesContainer: {
        flex: 0.7,
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderTopRightRadius: 5, 
        padding:0
    },
    messageLeft: {
    },
    messageRight: {
        "& .rce-mbox": {
            backgroundColor: blue,
            color: "#fff",
        },
        "& .rce-mbox-right-notch": {
            borderColor: blue,
            color: blue,
            fill: blue,
        },
    },
    navbar: {
        background: `#44adab !important`,
        height: '30px',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '10px 20px',
        borderTopRightRadius: 5,
        gap:40,
        "& .rce-navbar-item__right":{
            position: 'absolut ',
            right: '2px'
        }
    },
    navbarUser: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 15,
        "& .rce-avatar": {
            background: "#fff !important",
            height: "25px",
            borderRadius: '50%'
        },
        "& .rce-avatar-container": {
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 25,
            height: 25,
            border: '1px solid white',
            borderRadius: '50%'
        },
    },
    navbarButtons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 15,
    },
    messageList: {
        padding: 30,
        overflowY: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        height: "calc(100vh - 120px)",
    },
    chatMessagesEmpty: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 15,
    },
})


export const chatListStyles = theme => ({
    chatItem: {
        "& .rce-avatar-container": {
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            border: '1px solid #44adabb2',
            borderRadius: '50%'
        },
        "& .rce-citem-body": {
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',  
        },
        "& .rce-avatar": {
            background: "#fff !important",
            height: "20px",
            width: "20px"
        },
        "& .rce-citem-body--top": {
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        "& .rce-citem-body--top-title": {
            fontSize: 14,
            marginLeft: 10,
            fontWeight: 600,
            fontFamily: 'Lato, sans-serif'
        },
        "& .rce-citem":{
            display: "flex"
        },
        "& .rce-citem-body--top-time": {
            paddingLeft: 8,
            marginLeft: 15,
            fontFamily: 'Lato, sans-serif',
            fontSize: 12,
            color: '#7e8383'
        }
    }
})


export const chatStyles = theme => ({
    chat: {
        width: "100%",
        height: "400px",
        display: "flex",
        flexDirection: "column",
        "& .conversation-container": {
            flex: 1,
            overflowY: "auto !important",
            display: "flex",
            flexDirection: "column",
            padding: 30,
        },
        "& .conversation-input-wrapper": {
            padding: "20px 10px",
            justifySelf: "flex-end",
            display: "flex",
            gap: 20,
            "& .ant-btn": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                    background: "#fff",
                }
            }
        }
    }
});