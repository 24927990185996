import React, {useState} from "react";
import fixtures from '../../fixtures';
import { motion } from "framer-motion";
import {Col, Row, Form, Select, Input, Upload, message } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { NewTicket } from "../../../../hooks/api/tickets/newTicket";
import './styles.css'
const { TextArea } = Input;
const { Dragger } = Upload;



const CreateTicket = ({ref1, ref2, ref3, ref4, ref5, setEmitSocket, cardTicketData, tickets,  empresasOption, licenciasOption, onChangeEmpresa, getData, form, selectEmpresa, listLicences}) => {
    
    const {newTicketMutation} = NewTicket();
    const [fileList, setFileList] = useState([]);


    //FILES CONTROL
    const allowedFileTypes = [
        "application/vnd.ms-excel", // xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
        "application/msword", // doc
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
        "application/pdf", // pdf
        "image/jpeg", // jpeg
        "image/png", // png
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    const handleFileUpload = (file) => {
        const isFileTypeAllowed = allowedFileTypes.includes(file.type);
        const isFileSizeValid = file.size <= maxFileSize;
    
        if (!isFileTypeAllowed) {
            message.error("Solo se permiten archivos de tipo: xlsx, xls, doc, docx, pdf, jpeg, jpg, png");
            return false;
        }
    
        if (!isFileSizeValid) {
            message.error('El archivo es demasiado grande, el tamaño máximo permitido es de 5MB.');
            return false;
        }
    
        // Agregar el archivo a la lista de archivos seleccionados
            setFileList([...fileList, file]);
    
        return false;
    };

    
    


    //FIN FORMULARIO
    const onFinish = (values) =>{
        const idLicence = values.licencia.value !== undefined ? values.licencia.value : values.licencia
        const version = listLicences.find(licence => licence.usli_id === idLicence).csiv_id !== null ? listLicences.find(licence => licence.usli_id === idLicence).csiv_id : 29 
        let dataTicket = {
            "empresa": values.empresa.value !== undefined ? values.empresa.value : values.empresa,
            "licencia": idLicence,
            "problematica": values.problematica,
            "observaciones": "",
            "version": version,
            "files": values.files !== undefined ? fileList : []
        }
        setEmitSocket(true)
        newTicketMutation(dataTicket,
            {
                onSuccess: (data) => {
                        message.success(data.mensaje);
                        getData()
                        form.resetFields();
                },
                onError: (error) =>{
                    message.error(error)
                }
        })
    } 

    return(
        <>
            <motion.div className="createTicket-card"
            initial={fixtures().cardInitial}
            animate={fixtures().cardAnimated}
            transition={fixtures().cardTransition}
            >
            <Row>
                <Col span={24} className="titleCreateTicket">
                    Crea un ticket
                </Col>
            </Row>
            <Form layout="vertical"  form={form} onFinish={onFinish}>
                <Row style={{marginTop:5}}>
                    <Col span={9} style={{padding: '1px 5px', marginRight:10}} ref={ref1}>
                        <Form.Item name='empresa' label='Empresa' rules={[{ required: true, message: 'Seleccione una empresa' }]}>
                            <Select
                                placeholder="Selecciona una empresa"
                                optionFilterProp="children"
                                onChange={onChangeEmpresa}
                                options={empresasOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={9} style={{padding: '1px 5px'}} ref={ref2}>
                        <Form.Item name='licencia' label='Licencia' rules={[{ required: true, message: 'Seleccione una licencia' }]}>
                            <Select
                                placeholder="Selecciona una licencia"
                                optionFilterProp="children"
                                options={licenciasOption}
                                disabled={!selectEmpresa}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginTop:-10}}>
                    <Col span={24} ref={ref3}>
                        <Form.Item name='problematica' label='Problemática' rules={[{ required: true, message: 'Describa su problemática' }]} >
                            <TextArea  rows={3} maxLength={200}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} ref={ref4}>
                        <Form.Item name='files'>
                            <Dragger
                                name="file"
                                multiple={false}
                                beforeUpload={handleFileUpload}
                                fileList={fileList}
                                onRemove={(file) => {
                                    const updatedList = fileList.filter((item) => item.uid !== file.uid);
                                    setFileList(updatedList);
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Haz clic o arrastra el archivo a esta área para subirlo</p>
                                <p className="ant-upload-hint">Solo se permiten archivos de tipo: xlsx, xls, doc, docx, pdf, jpeg, jpg, png y con un tamaño máximo de 5MB.</p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginTop:-15}}>
                    <Col span={24} ref={ref5}>
                        <motion.button  whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                            type='submit'  className='btn-Primary btnSendTicket'>
                            Enviar
                        </motion.button>
                    </Col>
                </Row>
            </Form>

            </motion.div>
        </>
        
    )

}

export default CreateTicket;