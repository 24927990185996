import React, {useState, useEffect} from "react";
import {Row, Col, Avatar, Space, Input, Upload, message, Button} from "antd";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { PostRecursoIncidencia } from "../../../../hooks/api/tickets/postRecursoIncidencia";
import { GetTicket } from "../../../../hooks/api/tickets/getTicket";
import { PostIncidencia } from "../../../../hooks/api/tickets/postIncidencia";
import { PaperClipOutlined, SendOutlined} from '@ant-design/icons';
import fixtures from "../../fixtures";
import logoDefatult from '../../../../assets/imgs/logoDefault.png'
import './styles.css'

const CardConversation = ({headData}) => {

    const {postIncidenciaMutation} = PostIncidencia();
    const {getTicketMutation} = GetTicket();
    const {postRecursoIncidencia} = PostRecursoIncidencia();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const apiBase = 'http://164.92.69.186/api/static';
    const [parametro, setParametro] = useState(queryParams.get('id'));
    const [isDisabled, setIsDisabled] = useState(queryParams.get('close') === 'true' || queryParams.get('active') === 'false' ? true : false);
    const [arrayConversacion, setArrayConversacion] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [statusTicket, setStatusTicket] = useState(null);
    const [fileList, setFileList] = useState([]);

        //FILES CONTROL
        const allowedFileTypes = [
            "application/vnd.ms-excel", // xls
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
            "application/msword", // doc
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
            "application/pdf", // pdf
            "image/jpeg", // jpeg
            "image/png", // png
        ];
        const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
    
        const handleFileUpload = (file) => {
            const isFileTypeAllowed = allowedFileTypes.includes(file.type);
            const isFileSizeValid = file.size <= maxFileSize;
        
            if (!isFileTypeAllowed) {
                message.error("Solo se permiten archivos de tipo: xlsx, xls, doc, docx, pdf, jpeg, jpg, png");
                return false;
            }
        
            if (!isFileSizeValid) {
                message.error('El archivo es demasiado grande, el tamaño máximo permitido es de 5MB.');
                return false;
            }
        
            // Agregar el archivo a la lista de archivos seleccionados
                setFileList([...fileList, file]);
        
            return false;
        };


    const myFunction = () => {
        getTicketMutation(parametro,
            {
                onSuccess: (data) => {
                    if(data.length !== 0){
                        setStatusTicket(data.status.stat_id)
                        const incidencias = data.incidencias.filter((item) => (item.tipi_id === 3 || item.tipi_id === 1));
                        setArrayConversacion(incidencias);
                    }
                },
                onError: (error) =>{
                    message.error(error)
                }
        })
    };

    useEffect(() => {
        myFunction();
        
    }, [])
    
    useEffect(() => {
        const intervalId = setInterval(myFunction, 2000);
        return () => clearInterval(intervalId);
    }, []);


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    }

    const sendIncidencia = () =>{
        postIncidenciaMutation({parametro, inputValue, statusTicket},
            {
                onSuccess: (data) => {
                    let params = {
                        id: data.data.tici_id,
                        files: fileList
                    }
                    postRecursoIncidencia(params, {
                        onSuccess: (data) => {
                            setFileList([])
                        },
                        onError: (error) => {
                            console.log(error)
                            setFileList([])
                        }
                    })
                    setInputValue('')
                },
                onError: () =>{
                    message.error("error")
                }
        })
    }

    return(
        <motion.div className="cardConversation" 
            initial={fixtures().cardInitial}
            animate={fixtures().cardAnimated}
            transition={fixtures().cardTransition}
            >
                <Row>
                    <Col span={8}>
                        <span className="titlesMsg">{headData !== undefined ? `Comunicación: ${headData.comunication}`: 'Comunicación:'}</span>
                    </Col>
                    <Col span={8}>
                        <span className="titlesMsg">{headData !== undefined ? `Titulo: ${headData.title}`: 'Titulo:'}</span>
                    </Col>
                    <Col span={8}>
                        <span className="titlesMsg">{headData !== undefined ? `Estatus: ${headData.status}`: 'Estatus:'}</span>
                    </Col>
                    
                </Row>
                <div className="cardMessages">
                    <Row>
                        <Col span={24} style={{display:'flex', justifyContent:'flex-start', alignItems:'center', marginBottom:10}}>
                            <span className="anotationTitle">Anotación</span>
                        </Col>
                    </Row>
                    {arrayConversacion !== undefined && arrayConversacion.map((frase, index) => {
                        if(frase.tici_observaciones !== null && frase.tici_visiblecliente === 1){
                            if(frase.tici_nombre === 'Incidencia del cliente' ){
                                return (
                                    <Row key={index} style={{marginTop:10}}>
                                        <Col xs={3} sm={3} md={2} lg={2} xl={2} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <Avatar src={logoDefatult}  style={{border:'1px solid black'}}/>
                                        </Col>
                                        <Col xs={21} sm={21} md={22} lg={22} xl={22} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                            <span className={headData !==undefined && headData.id === frase.tici_id ? 'messageSelect' : "messageData"}>{frase.tici_observaciones}</span>
                                        </Col>
                                    </Row>
                                )
                            }else{
                                return (
                                    <Row key={index} style={{marginTop:10}}>
                                        <Col xs={21} sm={21} md={22} lg={22} xl={22} style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                            <span className={headData !==undefined && headData.id === frase.tici_id ? 'messageSelect' : "messageData"}>{frase.tici_observaciones}</span>
                                        </Col>
                                        <Col xs={3} sm={3} md={2} lg={2} xl={2} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <Avatar src={logoDefatult}  style={{border:'1px solid black'}}/>
                                        </Col>
                                    </Row>
                                )
                            }
                        }else{
                            return(null)
                        }
                    })}
                </div>
                <Row>
                    <Col span={18} className="colInput">
                        <Input  placeholder="Mensaje" className="inputMessage" value={inputValue} onChange={handleInputChange} disabled={isDisabled}/>
                    </Col>
                    <Col span={3} className="colBtnSend">
                        {inputValue.length > 0 && <SendOutlined  style={{fontSize:20}} onClick={()=>sendIncidencia()} />}
                    </Col>
                    <Col span={3}>
                            <Upload
                                name="file"
                                multiple={false}
                                beforeUpload={handleFileUpload}
                                fileList={fileList}
                                className="upInci"
                                onRemove={(file) => {
                                    const updatedList = fileList.filter((item) => item.uid !== file.uid);
                                    setFileList(updatedList);
                                }}
                            >   
                                <Button className="colIconFiles" icon={<PaperClipOutlined style={{fontSize:20}} />}></Button>
                            </Upload>  
                    </Col>
                </Row>
            </motion.div>
    )
}

export default CardConversation;