import React, {useState, useEffect} from "react";
import {Row, Col, Avatar, message } from "antd";
import ModalAdjuntos from "../modalAdjuntos/modalAdjuntos";
import { motion, AnimatePresence} from "framer-motion";
import { useLocation } from "react-router-dom";
import { GetTicket } from "../../../../hooks/api/tickets/getTicket";
import { Rating } from "@mui/material";
import {PaperClipOutlined } from '@ant-design/icons';
import moment from "moment";
import logoDefatult from '../../../../assets/imgs/logoDefault.png'
import fixtures from "../../fixtures";
import './styles.css';

const CardSeguimiento = ({setHeadData, setIncidencias, isOpen, setIsOpenAdjuntos, setAdjuntos}) => {

    const {getTicketMutation} = GetTicket();
    
    // const apiBase = 'http://164.92.69.186/api/static'

    const location = useLocation();
    const token = sessionStorage.getItem('tokenKronox');
    const queryParams = new URLSearchParams(location.search);
    const [dataSource, setDataSource] = useState(null);
    const [folio, setFolio] = useState(queryParams.get('folio'));
    const [id, setId] = useState(queryParams.get('id'));

    const arrayComunication  = ['Nota', 'Correo Entrante', 'Correo Saliente', 'Llamada Entrante', 'Llamada Saliente' ];
    const arrayStatus = ['Todos', 'Ticket Libre', 'Ticket Seguimiento', 'Espera Cliente', 'Cerrado con Exito', 'Cerrado por Cliente', 'Cerrado sin Exito', 'Espera Fabricante', 'Caducado']

    useEffect(()=> {
        if (!token) {
            window.location.href = '/login';
        }
        else{
            getIncidencias();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        
        const intervalId = setInterval(getIncidencias, 2000);
        return () => clearInterval(intervalId);
    }, []);

    const changeSeguimiento = (id, idCom, title, idStat) => {
        const data = {
            id: id,
            comunication: arrayComunication[idCom-1],
            title: title,
            status: arrayStatus[idStat]
        }
        setHeadData(data)
    }

    const getIncidencias = () => {
                    getTicketMutation(id,
                        {
                            onSuccess: (data) => {
                                if(data !== undefined){
                                    const incidencias = data.incidencias.filter(item => item.tipi_id === 3 || item.tipi_id === 6);
                                    const files = incidencias.map((incidencia, index) => {
                                        if(incidencia.recursos.length>0){
                                            return{
                                                key: index+1,
                                                recursos:incidencia.recursos
                                            }
                                        }else{
                                            return null
                                        }
                                    })
                                    setAdjuntos(files);
                                    setIncidencias(incidencias);
                                    setDataSource(incidencias);
                                }else{
                                    setAdjuntos([])
                                    setIncidencias([]);
                                    setDataSource([]);
                                }
                            },
                            onError: (error) =>{
                                message.error(error)
                            }
                    })
    }

    const openAdjuntos = () => {
        setIsOpenAdjuntos(true)
    }



    return(
            <>
                    {isOpen && (
                        <motion.div
                        className="cardSeguimientoData"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                        >
                        {dataSource !== null ? (
                            <>
                                <Row>
                                    <Col span={24} className="colTitleSeguimiento">
                                        <span className="folioSeguimiento">{folio}</span>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:20, marginBottom:20}}>
                                    <Col span={20} className="colRegisterCount">
                                        {dataSource !== null ? (<div><span className="textRegisterCount">Registros:</span> <span className="registerCount">{dataSource.length}</span></div>) : null}
                                    </Col>
                                    <Col span={4} className="colRegisterCount">
                                    <motion.button whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                                        type='submit'  className='btn-Primary btnAdjuntos' onClick={()=>{openAdjuntos()}}>
                                            <PaperClipOutlined style={{fontSize:15}}/>
                                    </motion.button>
                                    </Col>
                                </Row>
                                {dataSource !== null ? dataSource.map((item, index)=> (
                                    <div key={index}>
                                        {item.tici_visiblecliente === 1 ? (
                                            <Row key={index} onClick={()=>changeSeguimiento(item.tici_id, item.tici_comunicacion, item.tici_nombre, item.stat_id )} >
                                                <Col span={3}>
                                                    <Avatar  className='iconNumber' >{index + 1}</Avatar>
                                                </Col>
                                                <Col span={3}>
                                                    <Avatar src={logoDefatult}  style={{border:'1px solid black'}}/>
                                                </Col>
                                                <Col span={14} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                    <span className="titleSeguimiento">{item.tici_nombre}</span>
                                                </Col>
                                                <Col span={2} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                {item.tici_importante === 1 ? (
                                                        <Rating  value={1} max={1} readOnly />
                                                ) : null}
                                                </Col>
                                        
                                                <Col span={2} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                    {item.recursos.length > 0 ? (
                                                            <PaperClipOutlined />
                                                    ) : null}
                                                </Col>
                                                <Col span={24} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                                    <span className="fechaSeguimiento">{moment(item.tici_fecha).format("DD/MM/YYYY hh:mm A")}</span>
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </div>
                                )):null}
                            </  >
                        ):null}
                        
                        </motion.div>
                    )}
                
            </>
    )
}

export default CardSeguimiento;