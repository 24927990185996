import useApi from "./useApiRequest";
import { useMutation } from "react-query";


export const UseLogin = (options) => {
    const api = useApi();

    /**
     * 
     * @param {MutatingLogin} params el tipo {@link MutatingLogin} se utilizará para las acciones create y update
     * returns 
    */
    const mutate = async (params) => {
            const data  = await api.noSessionPost(`/login`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        mutateLogin: mutation.mutate,
    };
};