import React, {useState} from "react";
import { Layout, Row, Col, Dropdown, Avatar, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import Kronox_Nombre from '../../assets/imgs/logotipoKronoxcorto.png'
import Logo from '../../assets/imgs/LogoKronox2.png'
import {    UserOutlined, FileTextOutlined, LogoutOutlined, 
            HomeOutlined, QuestionCircleOutlined, ShopOutlined, FileProtectOutlined, UsergroupAddOutlined
        } from '@ant-design/icons';
import './styles.css'
// import AttachMoneyRounded from "@mui/icons-material/AttachMoneyRounded";

const { Header} = Layout

const HeaderLayout = ({openTour, setOpenTour}) =>  {

    let user = JSON.parse(sessionStorage.getItem('userKronox'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setIsModalOpen(false);
            sessionStorage.removeItem('tokenKronox')
            sessionStorage.removeItem('userKronox')
            sessionStorage.removeItem('messageShow')
            window.location.href = '/login';
        }, 1500);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const openModalTour = () => {
        if(openTour === false){
            setOpenTour(true)
        }
    }
    //PRIMER LETRA MAYUSCULA CADENA
    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    //ITEMS OPCIONES MENU    
    const items = [
        {
            key: '1',
            label: (
                <Row style={{cursor:'default'}}>
                    <Col span={8}><Avatar shape="square" size={60} icon={<UserOutlined />} /></Col>
                    {user != null ? (<Col span={16} className="nameUser">{`${capitalizeFirstLetter(user.usu_nombres)} ${capitalizeFirstLetter(user.usu_apaterno)} ${capitalizeFirstLetter(user.usu_amaterno)}`}</Col>): null}
                </Row>
            ),
        },
        {
            key: '2',
            label: (
                <Link to='/ticketsAdministracion' className="linkTickets" >
                    <Row style={{marginTop:10, display:'flex', alignItems:'center', height:20}}>
                        <Col span={1}><HomeOutlined /></Col>
                        <Col span={22} className="listaMenuItem"><p style={{display:'inline'}}>Inicio</p></Col>
                    </Row>
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <Link to='/cliente_perfil' className="linkTickets" >
                    <Row style={{display:'flex', alignItems:'center', height:20}}>
                        <Col span={1}><UserOutlined /></Col>
                        <Col span={22} className="listaMenuItem"><p style={{display:'inline'}}>Mi perfil</p></Col>
                    </Row>
                </Link>
            ),
        },
        {
            key: '4',
            label: (
                <Link to='/tickets_tabla' className="linkTickets" >
                    <Row style={{marginTop:0, display:'flex', alignItems:'center', height:20}}>
                        <Col span={1}><FileTextOutlined /></Col>
                        <Col span={22} className="listaMenuItem"><p style={{display:'inline'}}>Mis tickets</p></Col>
                    </Row>
                </Link>
            ),
        },
        {
            key: '5',
            label: (
                <Row style={{marginTop:20, display:'flex', alignItems:'center', height:20}} onClick={showModal}>
                    <Col span={1}><LogoutOutlined /></Col>
                    <Col span={22} className="listaMenuItem"><p style={{display:'inline'}}>Salir</p></Col>
                </Row>
            ),

        }
    ]


    // user.usu_tipo === 2 && {
    //     key: '7',
    //     label: (
    //         <Link to='/mis_usuarios' className="linkTickets" >
    //             <Row style={{marginTop:0, display:'flex', alignItems:'center', height:20}}>
    //                 <Col span={1}><UsergroupAddOutlined /></Col>
    //                 <Col span={22} className="listaMenuItem"><p style={{display:'inline'}}>Mis Usuarios</p></Col>
    //             </Row>
    //         </Link>
    //     ),
    // }, 
        //     {
    //         key: '5',
    //         label: (
    //             <Link to='/paquetes' className="linkTickets" >
    //                 <Row style={{marginTop:0, display:'flex', alignItems:'center', height:20}}>
    //                     <Col span={1}><ShopOutlined /></Col>
    //                     <Col span={22} className="listaMenuItem"><p style={{display:'inline'}}>Tienda</p></Col>
    //                 </Row>
    //             </Link>
    //         ),
    //     },
    //     {
    //         key: '6',
    //         label: (
    //             <Link to='/documentos' className="linkTickets" >
    //                 <Row style={{marginTop:0, display:'flex', alignItems:'center', height:20}}>
    //                     <Col span={1}><FileProtectOutlined /></Col>
    //                     <Col span={22} className="listaMenuItem"><p style={{display:'inline'}}>Mis Documentos</p></Col>
    //                 </Row>
    //             </Link>
    //         ),
    //     },


    return(
        <>
            <Header className="headerLayout" >
                <Row style={{height:'100%'}}>
                    <Col xs={4} sm={{offset:1, span:4}} md={10} lg={11} xl={8} xxl={8} style={{height:'100%'}}>
                        <Row className="rowLogo" >
                            <Link to='/ticketsAdministracion' className="colLogo" >
                                <Col xs={24} sm={3} md={3} lg={3} xl={3} xxl={3} className="colLogo"><img src={Logo} alt="logotipo empresa" className="imgLogo"  /></Col>
                            </Link>
                            <Col span={19} offset={1} className="colNombre"><img src={Kronox_Nombre} alt="nombre empresa" className="imgNombre" /></Col>
                        </Row>
                    </Col>
                    <Col xs={{offset:9, span:8}} sm={{offset:11, span:6}} md={{offset:6, span:5}} lg={{offset:7, span:3}} xl={{offset:10, span:3}} xxl={3} style={{height:'100%'}}>
                        <Row className='rowMoney' onClick={openModalTour}>
                                <Col span={18} style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                    <span className="textHelp">Guia de Ayuda</span>
                                </Col>
                                <Col span={6} style={{display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:5}}>
                                    <QuestionCircleOutlined className="iconHelp" />
                                </Col>
                                {/* <Col span={8} className='colHeadersMoney ColIconMoney'>
                                    <div className='iconMoneyContainer'>
                                        <AttachMoneyRounded  className='iconMoney'/>
                                    </div>
                                </Col>
                                <Col span={14}  className='colHeadersMoney'>
                                    <span className='moneyText'>$150</span>
                                </Col> */}
                        </Row>
                    </Col>
                    <Col xs={{offset:0, span:3}} sm={{offset:0, span:2}} md={{offset:0, span:2}} lg={{offset:0, span:1}} xl={{offset:0, span:2}} xxl={2} style={{height:'100%'}}>
                        <Row className='rowMoney'>
                            <Col span={24} className='colHeadersMoney'>
                                <Dropdown menu={{items}} placement="bottomRight">
                                    <Avatar size={35} icon={<UserOutlined />} />
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Header>
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className='modalSesion' footer={[
                <Button key="back" className='btnCancelSsn' onClick={handleCancel}>Cancelar</Button>,
                <Button key="submit" className='btn-Primary btnAceptarModal' loading={loading} onClick={handleOk}>Aceptar</Button>]}>
                <Row style={{marginTop:20}}>
                    <span className='titleModalSesion'>¿Desea cerrar sesión?</span>
                </Row>
            </Modal>
        </>
    )

}

export default HeaderLayout;