const initialForgetPass = { fontSize: 14, color:'#7A959B'}
const hoverForgetPass = { fontSize: 30, color:'#44ADAB', transition:2}
const tapForgetPass = { fontSize: 12, color:'#44ADAB'}
const hoverbtnLogin = { scale: 1.05,}
const tapbtnLogin = { scale: 0.9,}
const initialBtn = { scale: 1, borderRadius: "6px" }
const succesBtn = { borderRadius: "50%", width: "50px", height: "50px", scale: 1, color: 'white', transition:1.5 }
const cardLoginInitial = {opacity: 0, y: 150}
const cardLoginAnimate = {opacity: 5, y: -10}
const cardLoginTransition = {duration:0.7}


const fixtures = () => {
    let fixture = {};
   
    fixture = {
        initialForgetPass,
        hoverForgetPass,
        tapForgetPass,
        hoverbtnLogin,
        tapbtnLogin,
        cardLoginInitial,
        cardLoginAnimate,
        cardLoginTransition,
        initialBtn,
        succesBtn
    };
  
    return fixture;
  };
  
  export default fixtures;