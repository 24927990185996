import useApi from "../useApiRequest";
import { useMutation } from "react-query";

export const GetEmpresas = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingEmpresas} params el tipo {@link MutatingEmpresas} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async () => {
        const data  = await api.get(`/clientes/empresas`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getEmpresasMutation: mutation.mutate,
    };
}