import React, { useEffect, useRef } from "react";
import ChatList from "./chat-list";
import { createUseStyles } from "react-jss";
import { Avatar, Navbar } from "react-chat-elements";
import { chatContainerStyles } from "./styles";
import { CloseOutlined } from "@ant-design/icons";
import { IMAGES } from "../../../../assets/imgs";
import {Button, Typography, Modal, Row, Col} from "antd";
import Lottie from "lottie-web";
import { LOTTIE_ANIMATIONS } from "../../../../assets/lottie";
import Conversation from "./conversation";
import Logo from '../../../../assets/imgs/logoDefault.png'
import './styles.css'

const useStyles = createUseStyles(chatContainerStyles);


const Chat = ({isModalOpen, chatInUse, setChatInUse, chatList, closeChat}) => {
    const styles = useStyles();
    const animationContainer = useRef();
    const apiResources = 'http://192.168.100.22:3001/api/static/';

    const onSelectChatItem = (chat) => {
        let newChat = chat;
        setChatInUse(newChat);
    }

    useEffect(() => {
        Lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: LOTTIE_ANIMATIONS.CHAT_CONVERSATION,
        });
    }, []);

    return(
        <Modal  open={isModalOpen} footer={null} 
                destroyOnClose closable={false}
                className="ModalChat"
                width={800} key={'modal-chat'}>
                    <Row>
                        <Col span={10} style={{borderRight: '1px solid #ddd', paddingBottom: 10}}>
                            <div className={styles.chatListContainer}>
                                <ChatList key={0} onSelectChatItem={onSelectChatItem} chats={chatList}/>
                            </div>
                        </Col>
                        <Col span={14} style={{paddingBottom:15}}>
                            <div className={styles.chatMessagesContainer}>
                                <Navbar className={styles.navbar}
                                        left={
                                            <div className={styles.navbarUser}>
                                                {
                                                    chatInUse &&
                                                    <>
                                                        <Avatar
                                                            src={chatInUse?.photo ? `${apiResources}/${chatInUse?.photo}` : IMAGES.GENERIC_USER}
                                                            size="small"
                                                            type="circle flexible"
                                                        />
                                                        <Typography.Title level={5} style={{ margin: 0, color: "white" }}>
                                                            {chatInUse?.user_list[0]?.usu_nombres} {chatInUse?.user_list[0]?.usu_apaterno} {chatInUse?.user_list[0]?.usu_amaterno}
                                                        </Typography.Title>
                                                    </>
                                                }
                                            </div>
                                        }
                                        right={
                                            <div>
                                                {
                                                    isModalOpen &&
                                                    <Button onClick={closeChat}  shape="circle" icon={<CloseOutlined />} />
                                                }
                                            </div>
                                        }
                                />
                                {
                                    chatInUse && <Conversation conv_id={chatInUse?.conv_id} />
                                }
                                {
                                    !chatInUse &&
                                    <div className={styles.chatMessagesEmpty}>
                                        {/* <div ref={animationContainer} style={{ position: "absolute", margin: -20, zIndex: 0, opacity: 0.8, width: 250 }}></div> */}
                                        <img src={Logo} alt="logo_kronox" />
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
        </Modal>
    )
}


export default Chat;