import useApi from "../useApiRequest";
import { useMutation } from "react-query";

export const GetUserID = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingUsuario} params el tipo {@link MutatingUsuario} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (id) => {
        const data  = await api.get(`/usuarios/${id.id}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getUserIDMutation: mutation.mutate,
    };
}