import React, { useState } from "react";
import {Tabs } from "antd";
import { motion } from "framer-motion";
import TabPersonal from "./tabs/tabPersonal/tabPersonal";
import TabCompany from "./tabs/tabCompany/tabCompany";
import TabSystems from "./tabs/tabLicense/tabSystems";
import TabPasswrd from "./tabs/tabPasswrd/tabPasswrd";
import fixtures from "../../fixtures";
import './styles.css'

const CardTabs = ({user, company, systems}) => {

    const items = [
        {
            key: '1',
            label: (<span className="titleTab">Personales</span>),
            children: (<TabPersonal user={user}/>),
        },
        {
            key: '2',
            label: (<span className="titleTab">Empresa</span>),
            children: (<TabCompany company={company}/>),
        },
        {
            key: '3',
            label: (<span className="titleTab">Sistemas</span>),
            children: (<TabSystems systems={systems}/>),
        },
        {
            key: '4',
            label: (<span className="titleTab">Contraseña</span>),
            children: (<TabPasswrd  />),
        },
    ];

    return(
        <motion.div className="cardTabs" 
        initial={fixtures().cardInitial}
        animate={fixtures().cardAnimated}
        transition={fixtures().cardTransition}>

            <Tabs defaultActiveKey="1" items={items}/>

        </motion.div>
    )
}

export default CardTabs;