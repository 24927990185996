import useApi from "../useApiRequest";
import { useMutation } from "react-query";

export const CrearDomicilio = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data_send = {
            ...params.data,
            dom_principal: true
        }
        const data  = await api.post(`/clientes/empresas/${params.id_empresa}/domicilios`, data_send, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        crearDomicilioMutation: mutation.mutate,
    };
}