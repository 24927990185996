import useApi from "../useApiRequest";
import { useMutation } from "react-query";

/**
 * 
 * @typedef MutatingAdress
 * @property {String} mutationType -  tipo de accion que se va a realizar
 */

export const PostAdress = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingAdress} params el tipo {@link MutatingAdress} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (params) => {
        const data  = await api.put(`/clientes/empresas/${params.id_empresa}/domicilios/${params.id_adress}`, params.data, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        postAdress: mutation.mutate,
    };
}