const hoverbtnLogin = { scale: 1.05,}
const tapbtnLogin = { scale: 0.9,}
const cardInitial = { opacity: 0, scale: 0.5 };
const cardAnimated = { opacity: 1, scale: 1 };
const cardTransition = {duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01]};

const fixtures = () => {
    let fixture = {};
   
    fixture = {
        hoverbtnLogin,
        tapbtnLogin,
        cardInitial,
        cardAnimated,
        cardTransition,
    };
  
    return fixture;
  };
  
  export default fixtures;