export const scrollToSelector = (selector) => {
    document.querySelector(selector)?.scrollIntoView({
        behavior: "smooth",
    });
};

export const setScrollToTop = (selector) => {
    // document.querySelector(selector).scrollTop({ block: "start", behavior: "smooth" });
    // document.querySelector(selector).scrollIntoView({ block: "start", behavior: "smooth" });
    document.querySelector(selector).scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
};

export const setScrollTop = (selector, top, smooth = true) => {
    // document.querySelector(selector).scrollTop({ block: "start", behavior: "smooth" });
    // document.querySelector(selector).scrollIntoView({ block: "start", behavior: "smooth" });
    document.querySelector(selector).scrollTo({
        top,
        left: 0,
        behavior: smooth ? "smooth" : "instant",
    });
};


export const setPageScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};


export const setScrollToBottom = (selector) => {
    const el = document.querySelector(selector);
    if (el)
        el.scrollTo({
            top: el.scrollHeight,
            left: 0,
            behavior: "smooth"
        });
};
