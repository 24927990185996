import React, {useState} from 'react';
import {Row, Col} from 'antd';
import FormData from './formData/formData';
import CodeVerification from './codeVerification/codeVerification';
import { motion } from 'framer-motion';
import './styles.css'
import fixtures from '../fixtures';


const ForgetPass = ({goLogin}) => {

    const [formData, setFormData] = useState({usu_mail: ''});
    const [codeSend,setCodeSend] = useState(false);

    const goLoginClick = () => {
        window.location.href = '/login';
    }

    return(
        <motion.div className='cardForgetPass' initial={fixtures().cardLoginInitial} animate={fixtures().cardLoginAnimate} transition={fixtures().cardLoginTransition}>
            <Row>
                <Col span={24} >
                    <span className='titleForgetPss'>Recuperar Contraseña</span>
                </Col>
            </Row>
                {codeSend ? < CodeVerification formData={formData} setCodeSend={setCodeSend} /> : <FormData formData={formData} setFormData={setFormData} setCodeSend={setCodeSend}/>}
            <Row>
                <Col span={24} style={{marginTop:10}}>
                    <p className='textGoLogin'>Si ya estas registrado</p> <span className='goLogin' onClick={goLoginClick}>Inicia sesión</span>
                </Col>
            </Row>
            <Row style={{marginTop:20}}>
                <Col span={24}>
                    <a href={require('../../../assets/pdfs/aviso_privacidad.pdf')} target="_blank"  rel="noopener noreferrer" className='aviso'>Aviso de privacidad</a>
                </Col>
            </Row>
        </motion.div>
    )
}

export default ForgetPass;