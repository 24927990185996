import useApi from "../../../hooks/api/useApiRequest";
import { useMutation } from "react-query";

/**
 * 
 * @typedef MutatingEncuesta
 * @property {String} mutationType -  tipo de accion que se va a realizar
 */

export const PostEncuesta = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingEncuesta} params el tipo {@link MutatingEncuesta} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (params) => {
        const data  = await api.post(`/clientes/encuestas/1/contestar`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        postEncuestaMutation: mutation.mutate,
    };
}