import React, {useEffect, useState} from "react";
import {Table} from "antd";
import moment from "moment";
import fixtures from "./fixtures";
import './styles.css'

const TabSystems = ({systems}) => {

    const [rowsData, setRowsData] = useState([]);
    const typesLicenses = ['Anual', 'Tradicional', 'Demo']

    useEffect(()=> {
        if(systems !== null && systems !== undefined){
            if(systems.length > 0){
                const dataFiltered = systems.map((item, index) => {
                    return{
                        key: index,
                        system: item.sistema.sis_nombre,
                        serie: item.usli_numeroserie,
                        type: typesLicenses[item.usli_tipo-1],
                        certificate: 'Sin certificado',
                        date: moment(item.usli_fechacaducidad).format("DD-MM-YYYY "),
                        observations: item.usli_observaciones
                    }
                })
                setRowsData(dataFiltered)
            }
        }
    },[systems])

    return(
            <div style={{padding:'2%'}}>
                <Table 
                dataSource={rowsData} 
                columns={fixtures().columns}  
                scroll={{x:true}} 
                pagination={{pageSize:4}}  
                className="tableSystems"
                />
            </div>
    )
}

export default TabSystems;