import React, {useState, useEffect} from "react";
import { Checkbox, Input, Radio, Space, Typography } from "antd";
import './styles.css'


const Pregunta = ({id, pregunta, descripcion, tipoRespuesta, opciones, multiple, handleChangeAnswer }) => {


    // opiones parseadas 
    const [opt, setOpt] = useState([]);

    const onChangeAnswer = (e) => {
        handleChangeAnswer(e.target.value)
    }

    useEffect(() => {
        if (opciones) {
            try {
                setOpt(JSON.parse(opciones));
            } catch (error) {
                console.error("No se pudo generar las opciones de la pregunta ya que no tiene un formato json correcto")
            }
        }
    }, [opciones])


    return(
        <div>
        <div style={{marginBottom:20}}>
            <span className="preguntaTitulo" style={{ marginBottom: 3 }}>{pregunta}</span>
            {
                descripcion &&
                <Typography.Paragraph style={{ marginBottom: 5 }} type="secondary">Quis enim id velit adipisicing cillum ad enim commodo amet incididunt. Magna esse culpa dolore aliquip culpa laborum ex commodo deserunt incididunt id sint dolore. Et deserunt tempor adipisicing velit et veniam ullamco tempor.</Typography.Paragraph>
            }
        </div>
        <div style={{marginBottom:20}}>
            {
                tipoRespuesta === 1 && // tipo texto
                <Input.TextArea showCount onChange={(e) => onChangeAnswer({ target: { value: {enre_respuesta: e.target.value, enre_valoracion:0, encp_id:id} } })} />
            }
            {
                tipoRespuesta === 2 && !multiple &&  //  tipo opcion multiple (permitido 1 opcion)
                <Radio.Group>
                    <Space direction="vertical">
                        {
                            opt?.map((opcion, iOpcion) => <Radio className="radioBtn" key={`${pregunta?.encp_id}-${opcion.valoracion}-${iOpcion}`} onChange={() => onChangeAnswer({ target: { value: {enre_respuesta: opcion.label, enre_valoracion:opcion.valoracion, encp_id:id} } })} value={opcion.valoracion}>{opcion.label}</Radio>)
                        }
                    </Space>
                </Radio.Group>
            }
            {
                tipoRespuesta === 2 && multiple && //  tipo opcion multiple (permitido multiples opciones)
                <Checkbox.Group onChange={onChangeAnswer} >
                    <Space direction="vertical">
                        {
                            opt?.map((opcion) => <Checkbox className="checkBtn" onChange={(e) => e.target.checked ? onChangeAnswer({ target: { value: {enre_respuesta: opcion.label, enre_valoracion: opcion.valoracion, encp_id:id} } }) : null} value={opcion.valoracion}>{opcion.label}</Checkbox>)
                        }
                    </Space>
                </Checkbox.Group>
            }
        </div>
    </div>
    )

}


export default Pregunta;