import React, {useState} from 'react';
import {Modal, Col, Row, Input, Form, message} from 'antd';
import { CheckCircleOutlined, CheckCircleFilled, CheckOutlined } from '@ant-design/icons';
import { ChangePassword } from '../../hooks/api/password/createPassword';
import { motion } from 'framer-motion';
import './styles.css'


const ModalPassword = ({setOpen, setOpenMessage}) => {
    const {changePasswordMutation} = ChangePassword();
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(true);
    const [formSend, setFormSend] = useState(false)
    const [instructions, setInstructions] = useState([
        { instruccion: "Extensión de al menos 8 caracteres", validado: false },
        { instruccion: "Utilizar mayúsculas", validado: false },
        { instruccion: "Utilizar minúsculas", validado: false },
        { instruccion: "Utiliza números", validado: false },
        { instruccion: "Utiliza caracteres especiales", validado: false },
    ]);
    const buttonVariants = {
        initial: { scale: 1, borderRadius: "6px", color:'white' },
        hover: { scale: 1.1 },
        tap: { scale: 0.9 },
        success: { borderRadius: "50%", width: "50px", height: "50px", scale: 1, color: 'white', transition:4000 },
    };


    const validatePassword = (_, value) => {
        if (!value) {
            return Promise.reject('Intruduzca una contraseña');
        }
    
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?¿¡=.,;-_()@#$%^&*])[A-Za-z\d!?¿¡=.,;-_()@#$%^&*]{8,}$/;
        if (!regex.test(value)) {
            return Promise.reject('contraseña Inválida');
        }
    
        return Promise.resolve();
    };
    const validatePasswordConfirmation = (_, value) => {
        const passwordFieldValue = form.getFieldValue('passwrd');
    
        if (value && value !== passwordFieldValue) {
            return Promise.reject('La confirmación de contraseña no coincide');
        }
    
        return Promise.resolve();
    };

    const onFinish = (values) => {
        
        let newPass = {
            usu_pass: values.passwrd
        }
        changePasswordMutation(newPass,
            {
                onSuccess: (data) => {
                    if(data.success){
                        let userData = JSON.parse(sessionStorage.getItem('userKronox'))
                        userData.usu_cambiopass = true;
                        sessionStorage.setItem('userKronox', JSON.stringify(userData));
                        setFormSend(true)
                        setTimeout(() => {
                            setOpenModal(false)
                            setOpenMessage(true)
                        }, 800);
                    }else{
                        message.error(data.mensaje)
                    }
                    
                },
                onError: () =>{
                    message.error("error")
                }
            }
        )
    };

    const onChange = (event) =>{
        const instruccionesArray = [...instructions];
        instruccionesArray[0].validado = event.target.value.length > 7 ? true : false;
        instruccionesArray[1].validado = /[A-Z]/.test(event.target.value)
        instruccionesArray[2].validado = /[a-z]/.test(event.target.value)
        instruccionesArray[3].validado = /[0-9]/.test(event.target.value)
        instruccionesArray[4].validado = /[^a-zA-Z0-9]/.test(event.target.value)
        setInstructions(instruccionesArray)
    }
    const closeModal = () => {
        setOpen(false)
        setOpenMessage(true)
        setOpenModal(false)
    }
 


return(
        <Modal  open={openModal} closable={false} footer={null} >
            <Row>
                <Col span={24} style={{display:'flex', justifyContent:'flex-end'}}>
                    <span onClick={closeModal} className='closeModalPass'>x</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}  style={{display:'flex', justifyContent:'center'}}>
                    <span className='titleModalPss'>Crea una contraseña</span>
                </Col>
            </Row>
            {instructions.map((item, index) => (
                <Row className='rowInstructionPss' key={index}>
                    <span className='instruction'>{item.instruccion}</span>
                    <div>{instructions[index].validado === true ? <CheckCircleFilled className='circleFill'/> : <CheckCircleOutlined style={{marginLeft:10}}/>}</div>
                </Row>
            ))}
            <Row  style={{marginTop:25, display:'block'}}>
                <Form form={form} onFinish={onFinish}>
                    <Col span={24}>
                        <Form.Item name='passwrd' rules={[ { validator: validatePassword }]}> 
                            <Input.Password className='inputPsw' onKeyUp={onChange}  placeholder='Introduzca una contraseña'/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='passwrdConfirm' 
                            dependencies={['passwrd']} 
                            rules={[{ required: true, message: 'Intruduzca la confirmación' },{validator:validatePasswordConfirmation, message:'Las contraseñas no coinciden'}]}> 
                            <Input.Password className='inputPsw'  placeholder='Confirme la contraseña' />
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{marginTop:60, display: formSend ? 'flex':'block', justifyContent:'center'}}>
                        <Form.Item >
                            <motion.button
                                whileHover={buttonVariants.hover} whileTap={buttonVariants.tap}
                                animate={formSend ? buttonVariants.success : buttonVariants.initial}
                                type="submit" className='btnAceptar'>
                                    {formSend? <CheckOutlined /> : 'Aceptar'}
                            </motion.button>
                        </Form.Item>
                    </Col>
                </Form>
            </Row>
        </Modal>
    )
}


export default ModalPassword;