import './App.css';
import AppRouter from './router/router';
import HeaderLayout from './components/header/header';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import FooterLayout from './components/footer/footer';

function App() {

  const ventana = useLocation();
  const [tourOpen, setTourOpen] = useState(
                                            JSON.parse(localStorage.getItem('hideGuideAuto')) !== null && JSON.parse(localStorage.getItem('hideGuideAuto')) !== undefined ?
                                            !JSON.parse(localStorage.getItem('hideGuideAuto')) : false
  )
  return (
    <div className='App'>
      {ventana.pathname === '/ticketsAdministracion' || 
      ventana.pathname === '/tickets_tabla' || 
      ventana.pathname === '/ticket_info' ||
      ventana.pathname === '/cliente_perfil' || 
      ventana.pathname.startsWith('/paquetes') ||
      ventana.pathname === '/documentos' 
      // ventana.pathname === '/mis_usuarios'
      ? (<HeaderLayout openTour={tourOpen} setOpenTour={setTourOpen}/>) : null}
      <AppRouter openTour={tourOpen} setOpenTour={setTourOpen}/>
    </div>
  );
}

export default App;
