import useApi from "../../../hooks/api/useApiRequest";
import { useMutation } from "react-query";

/**
 * 
 * @typedef MutatingIncidencia
 * @property {String} mutationType -  tipo de accion que se va a realizar
 */

export const PostIncidencia = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingIncidencia} params el tipo {@link MutatingIncidencia} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (params) => {
        let parametros = {
            tipi_id: 3,
            stat_id: params.statusTicket, 
            tici_comunicacion: 1,
            tici_nombre:  params.mensaje ? params.mensaje : 'Mensaje del cliente',
            tici_observaciones: params.inputValue,
            tici_visiblecliente: 1
        }
        const data  = await api.post(`/clientes/tickets/${params.parametro}/incidencias`, parametros, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        postIncidenciaMutation: mutation.mutate,
    };
}