import React, { useState, useEffect } from "react";
import {Layout, Row, Col, message} from "antd";
import { DownOutlined } from "@ant-design/icons";
import CardTimer from "../components/cardTimer/cardTimer";
import CardSeguimiento from "../components/cardSeguimiento/cardSeguimiento";
import CardConversation from "../components/cardConversation/cardConversation";
import CardAsesor from "../components/cardAsesor/cardAsesor";
import ModalAdjuntos from "../components/modalAdjuntos/modalAdjuntos";
import ModalClose from "../components/modalClose/modalClose";
import { motion } from "framer-motion";
import fixtures from "../fixtures";
import './styles.css'

const { Content } = Layout;

const TicketsInfo = () => {

    const [headData, setHeadData] = useState();
    const [observers, setObservers] = useState([]);
    const [isOpenAsesor, setIsOpenAsesor] = useState(true);
    const [isOpenSeguimiento, setIsOpenSeguimiento] = useState(false);
    const [isOpenAdjuntos, setIsOpenAdjuntos] = useState(false);
    const [adjuntos, setAdjuntos] = useState(null);
 

    const openAsesor = () => {
        if(isOpenAsesor){
            setIsOpenAsesor(false)
            setIsOpenSeguimiento(true)
        }else{
            setIsOpenAsesor(true)
            setIsOpenSeguimiento(false)
        }
    }
    const openSeguimiento = () => {
        if(isOpenSeguimiento){
            setIsOpenSeguimiento(false)
            setIsOpenAsesor(true)
        }else{
            setIsOpenSeguimiento(true)
            setIsOpenAsesor(false)
        }
    }


    return(
        <Content >
            <Row>
                <Col xs={24} sm={24} md={24} lg={15} xl={15} style={{padding:'2%'}}>
                    <CardConversation   headData={headData} observaciones={observers}/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9} xl={9} style={{padding:'2%'}}>
                    <Row>
                        <Col span={24} style={{marginBottom:15}}><CardTimer   /></Col>
                        <Col span={24} style={{marginBottom:0}}>
                            <motion.div className="cardSeguimiento" 
                                initial={fixtures().cardInitial}
                                animate={fixtures().cardAnimated}
                                transition={fixtures().cardTransition}
                            >
                                <Row onClick={openAsesor} style={{padding:'1%'}}>
                                    <Col span={20} className="colTitleSeguimiento"><span className="titleCardSeguimiento" >Datos del Asesor</span></Col>
                                    <Col span={4} style={{display:'flex', justifyContent:'center', alignItems:'center'}} ><DownOutlined  className="titleCardSeguimiento"/></Col>
                                </Row>
                                <Col span={24} style={{marginBottom:15}}><CardAsesor isOpen={isOpenAsesor}/></Col>
                            </motion.div>
                        </Col>
                        

                        <Col span={24} style={{marginBottom:0}}>
                            <motion.div className="cardSeguimiento" 
                                initial={fixtures().cardInitial}
                                animate={fixtures().cardAnimated}
                                transition={fixtures().cardTransition}
                            >
                                <Row onClick={openSeguimiento} style={{padding:'1%'}}>
                                    <Col span={20} className="colTitleSeguimiento"><span className="titleCardSeguimiento" >Seguimiento de Ticket</span></Col>
                                    <Col span={4} style={{display:'flex', justifyContent:'center', alignItems:'center'}} ><DownOutlined  className="titleCardSeguimiento"/></Col>
                                </Row>
                            </motion.div>
                        </Col>
                        <Col span={24} style={{marginBottom:15}}><CardSeguimiento   
                        setHeadData={setHeadData} setIncidencias={setObservers} isOpen={isOpenSeguimiento} setIsOpenAdjuntos={setIsOpenAdjuntos} setAdjuntos={setAdjuntos}/></Col>
                    </Row>
                </Col>
            </Row>
            <ModalAdjuntos key={123} adjuntos={adjuntos} isOpen={isOpenAdjuntos} setOpen={setIsOpenAdjuntos}/>
        </Content>
    )
}

export default TicketsInfo;