import React, {useState, useEffect, useRef} from "react";
import { Col, Row } from "antd";
import { motion } from "framer-motion";
import { Manager } from "socket.io-client";
import fixtures from '../../fixtures'
import './styles.css'
import { useSelector } from "react-redux";
import { REACT_APP_API_TICKET_LIST } from "../../../../env";

const token = sessionStorage.getItem('tokenKronox')

const CardOrder = ({ref6, ref7, emitSocket, setEmitSocket}) => {
    const ioManager = useRef(null);
    const socketQueue = useRef(null)
    const [ticketsAtentionList, setTicketsAtentionList] = useState([]);
    const [ticketsNextList, setTicketsNextList] = useState([]);
    const { apiHost } = useSelector((state) => ({
        apiHost: REACT_APP_API_TICKET_LIST ,
    }));

    
    useEffect(() => {

        let arrayData = []

        ioManager.current = new Manager(`${apiHost}/socket.io/socket.io.js`, {
            extraHeaders: {
                authorization: `Bearer ${token}`,
            },
        });
        socketQueue.current = ioManager.current.socket("/");
        socketQueue.current.emit("get-tickets-queue", {});
        
        socketQueue.current.on("receiving-tickets", (res) => {
            arrayData = JSON.parse(res)
            filterListTickets(arrayData)
            setEmitSocket(false)
        });

        return () => {
            if (socketQueue.current) {socketQueue.current.disconnect(2)};
        }
    }, [emitSocket]);

    const filterListTickets = (data) => {
        const ticketsAten = data.filter(ticket => ticket.status === 1);
        const ticketsNext = data.filter(ticket => ticket.status === 2);
        setTicketsAtentionList(ticketsAten)
        setTicketsNextList(ticketsNext)
    }



    return(
        <motion.div className="cardCreateTicket" 
        initial={fixtures().cardInitial}
        animate={fixtures().cardAnimated}
        transition={fixtures().cardTransition}
        >
        <Row>
            <Col span={12} ref={ref6}>
                <Row className="container containerAtention">
                    <div className="divBadage badageAtencion">En atencion</div>
                    <div className="triangle trianguleAtention"></div>
                </Row>
                <Row>
                    {ticketsAtentionList.length > 0 ? 
                        (
                            <>
                                {ticketsAtentionList.map((ticketWait, index) => (
                                    <Col key={index} span={24} className="colTicketWait">
                                        <span className="ticketLabel" >{ticketWait.ticket}</span>
                                    </Col>
                                ))}
                            </>
                        ):
                        (
                            <Col span={24} style={{display:'flex', justifyContent:'flex-start'}}>
                                <span className="noList">Sin tickets en atención</span>
                            </Col>
                        )}
                    
                </Row>
            </Col>
            <Col span={12} ref={ref7}>
                <Row className="container containerNexts">
                    <div className="divBadage badageNexts">Siguientes</div>
                    <div className="triangle trianguleNexts"></div>
                </Row>
                <Row>
                    {ticketsNextList.map((ticketNext, index) => (
                        <Col key={index} span={24} className="colTicketNext">
                            <span className="ticketLabel" >{ticketNext.ticket}</span>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    </motion.div>
    )
}

export default CardOrder;