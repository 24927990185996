import React from "react";
import {Form, Row, Col, Input, message } from 'antd';
import { motion } from "framer-motion";
import { CheckEmail } from "../../../../hooks/api/register/checkEmail";
import { PostSendCodeForget } from "../../../../hooks/api/password/sendCodeForget";
import fixtures from "../../fixtures";
import './styles.css'

const FormData = ({formData, setFormData, setCodeSend}) => {

    const {mutateEmail} = CheckEmail();
    const {postSendCodeForgetMutation} = PostSendCodeForget();

    const validateEmail = (_, value) => {
        // Expresión regular para validar el formato de correo electrónico
        const emailRegex = /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;
        
        // Verificar si el valor cumple con el formato de correo electrónico
        if (value && !emailRegex.test(value)) {
            return Promise.reject('Ingresa un correo electrónico válido');
        }
        
        return Promise.resolve();
    };

    //METODO FINALIZAR FORM
    const onFinish = (values) => {
        let dataSendCode = {
            usu_mail: values.usu_mail,
        }
        mutateEmail({
            "email": values.usu_mail,
        },
            {
                onSuccess: (data)=>{
                    if(data.success){
                        postSendCodeForgetMutation(dataSendCode,
                            {
                                onSuccess: (data) => {
                                    if(data.success){
                                        setFormData(values);
                                        setCodeSend(true);
                                    }else{
                                        message.error(data.mensaje);
                                    }  
                                    
                                },
                                onError: (error) =>{
                                    message.error(error)
                                }
                            }
                        )
                    }else{
                        message.error('El correo no esta registrado')
                    }
                },
                onError: (error) =>{
                    message.error(error)
                }
            })
    }
    return(
        <Form onFinish={onFinish} className='formForgetOne' initialValues={formData}>
                <Row>
                    <Col span={24}>
                        <Form.Item name='usu_mail' rules={[{required:true, message: 'Ingrese un correo'}, { validator: validateEmail }]}>
                            <Input className='inputForget' placeholder='Correo eléctronico'/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <motion.button whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                            type='submit'  className='btn-Primary btnForget'>
                            Enviar Solicitud
                        </motion.button>
                    </Col>
                </Row>
            </Form>
    )
}

export default FormData;