import React, {useState, useEffect, useRef} from "react";
import { Row, Col, Modal, Button, Steps, message } from "antd";
import { useLocation } from "react-router-dom";
import { GetEncuesta } from "../../../../hooks/api/encuestas/getEncuesta";
import { PostEncuesta } from "../../../../hooks/api/encuestas/postEncuesta";
import Pregunta from "./components/pregunta/pregunta";
import './styles.css'

const ModalEncuesta= ({isModalOpen, setModalOpen, idTicket, isHome}) => {

    const {getEncuestaMutation} = GetEncuesta();
    const {postEncuestaMutation} = PostEncuesta();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = useState(idTicket !== undefined && idTicket !== null? idTicket : queryParams.get('id'));
    const [loading, setLoading] = useState(false);
    const [encuesta, setEncuesta] = useState(null);
    let respuestas = useRef(null);
    const [steps, setSteps] = useState(null);
    const [items, setItems] = useState(null);
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        getEncuestaMutation({},{
            onSuccess:(data)=>{
                setEncuesta(data)
                let preguntas = [...data.preguntas]
                respuestas.current = preguntas.map(pregunta => {
                    return { pregunta, respuesta: null }
                })
                let step = preguntas.map((pregunta, index) => {
                    return {
                        title: index,
                        content: (<Pregunta
                            id={pregunta?.encp_id} 
                            pregunta={pregunta?.encp_texto}
                            descripcion={pregunta?.encp_descripcion}
                            tipoRespuesta={pregunta?.encp_tipo}
                            opciones={pregunta?.encp_opciones}
                            multiple={pregunta?.encp_multiple}
                            handleChangeAnswer={(respuesta) => handleChangeAnswer(pregunta, respuesta)}/>)
                    }
                })
                const items = step.map((item) => ({ key: item.title, title: '' }));
                setItems(items)
                setSteps(step)
            },
            onError:(error)=>{
                message.error(error)
            }
        })

    }, [getEncuestaMutation]);

    const validaEncuesta = () => !respuestas.current.find(x => x.pregunta.encp_tipo === 2 && !x.respuesta);

    const calculaValoracion = () => {
        let valoracion = 0;
        for(let res of respuestas.current){
            if(res.respuesta !== null){
                
                valoracion+=res.respuesta.enre_valoracion;
            }
        }
        return valoracion;
    }

    const handleOk = () => {
        if(validaEncuesta()){

            setLoading(true);

            // calcular valoracion de la encuesta
            let valoracion = calculaValoracion();

            //Obtener solo respuestas del array
            let arrayRespuestas = respuestas.current.map(respuesta => {
                return respuesta.respuesta;
            })
            arrayRespuestas = arrayRespuestas.filter((res) => res !== null && res !==undefined)
            const params = {
                tick_id: id[0],
                valoracion: valoracion,
                respuestas: arrayRespuestas
            }
            setTimeout(() => {
                postEncuestaMutation(params, {
                    onSuccess:(data) => {
                        if(data.success){
                            setLoading(false);  
                            message.success(data.mensaje)
                            handleCancel();
    
                        }else{
                            message.error(data.mensaje)
                        }
                    }, 
                    onError: (error) => {
                        message.error(error)
                    }
                })
            }, 1500);
        }else{
            message.error("Aún hay preguntas por contestar");
        } 
    }
    const handleCancel = () => {
        setCurrent(0);
        setModalOpen(false);
        window.location.href=`/ticketsAdministracion`
    }

    const handleChangeAnswer = (pregunta, respuesta) => {
        respuestas.current = respuestas.current.map(x => {
            if(pregunta?.encp_id === x.pregunta.encp_id){
                return {...x, respuesta}
            }   
            return x;
        })
    }

    const next = () => {
        setCurrent(current + 1);
    };
    
    const prev = () => {
        setCurrent(current - 1);
    };




    return(
        
        <Modal open={isModalOpen} onOk={handleOk} cancelButtonProps={{style:{display:'none', overflowY:'hidden'}}} closable={false}  className='modalSesion' footer={null}>
                {encuesta !== null ? 
                (
                    <div style={{overflowY:'hidden'}}>
                        <Row style={{marginTop:20, marginBottom:20}} key={2}>
                            <Col span={24} style={{display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
                                <span className='titleEncuesta'>{encuesta.encu_nombre}</span>
                            </Col>
                            <Col span={24} style={{textAlign:'justify'}}>
                                <span className='descriptionEncuesta'>{encuesta.encu_descripcion}</span>
                            </Col>
                        </Row>
                        <Row >
                            {encuesta?.preguntas?.map((pregunta, index) => (
                                <Col span={24} key={index} style={{display:current === index ? 'block' : 'none'}}>
                                    <Pregunta
                                    id={pregunta?.encp_id} 
                                    pregunta={pregunta?.encp_texto}
                                    descripcion={pregunta?.encp_descripcion}
                                    tipoRespuesta={pregunta?.encp_tipo}
                                    opciones={pregunta?.encp_opciones}
                                    multiple={pregunta?.encp_multiple}
                                    handleChangeAnswer={(respuesta) => handleChangeAnswer(pregunta, respuesta)}
                                    />
                                </Col>
                            ))}
                            <Col span={24} style={{padding:5}}>
                                <div style={{ marginTop: 10, display:'flex', justifyContent:'flex-end' }}>
                                    {isHome ? (<Button key={0} className='btnCancelSsn' onClick={handleCancel}>Cancelar</Button>):null}
                                    {current > 0 && (
                                        <>
                                            <Button style={{ margin: '0 8px' }} className='btn-Primary btnAceptarModal' onClick={() => prev()}>
                                            Anterior
                                            </Button>
                                        </>
                                    )}
                                    {current < steps.length - 1 && (
                                    <>
                                        <Button  className='btn-Primary btnAceptarModal' onClick={() => next()}>
                                            Siguiente
                                        </Button></>
                                    )}
                                    {current === steps.length - 1 && (
                                        <>
                                            <Button className='btn-Primary btnAceptarModal' loading={loading} onClick={handleOk}>
                                                Enviar
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                ):
                (<p>No se pudo cargar la encuesta, favor de refrescar</p>)
                }
        </Modal>

    )
}

export default ModalEncuesta;