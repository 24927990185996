import useApi from "../useApiRequest";
import { useMutation } from "react-query";

export const GetUserChats = ()  => {
    const api = useApi();

    const mutate = async (id) => {
        const data  = await api.get(`/chats/user?user-chats=${id}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getUserChatsMutation: mutation.mutate,
    };
}