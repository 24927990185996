import axios from "axios";
import { useSelector } from "react-redux";
import {REACT_APP_API_HOST} from '../../env'

const useApi = () => {
    const { apiHost } = useSelector((state) => ({
        apiHost: REACT_APP_API_HOST,
    }));

    const noSessionPost = async (resource, data, options = {}) => {
        let reqOptions = {};
        try {
            let result = await axios.post(options.hasCustomUrl ? resource : `${apiHost}${resource}`,  data, reqOptions);
            return result;
        } catch (error) {
            
            return null;
        }
    };
    const getForUrl = async (resource, options = { dataType: "multipart", hasFormData: false, hasCustomUrl: false }) => {
        let reqOptions = null;
     if (options.dataType === "json") {
            reqOptions = {
                // withCredentials: true,
                // headers: { "Content-Type": "application/json", Authorization: `Bearer ${sToken}` },
            };
        }

        try {
            let result = await axios.get(options.hasCustomUrl ? resource : `${apiHost}${resource}`, reqOptions);
            return result;
        } catch (error) {
            
            return null;
        }
    };
    const get = async (resource) => {
        try {
            return await axios.get(`${apiHost}${resource}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenKronox')}`,
                },
            });
        } catch (error) {
            if (error.response.status === 401) {
            }
            return null;
        }
    };
    const post = async (resource, data, options = {}) => {
        let reqOptions = null;
        if (options.dataType === "json"){
            reqOptions = {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${sessionStorage.getItem('tokenKronox')}` },
            };
        }else{
            reqOptions = {
                headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${sessionStorage.getItem('tokenKronox')}` },
            };
        }
        try {
            let result = await axios.post(options.hasCustomUrl ? resource : `${apiHost}${resource}`, data, reqOptions);
            return result;
        } catch (error) {
            
            return error;
        }
    };
    const put = async (resource, data, options = {}) => {
        let reqOptions = null;
        reqOptions = {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${sessionStorage.getItem('tokenKronox')}` },
        };
        try {
            let result = await axios.put(options.hasCustomUrl ? resource : `${apiHost}${resource}`, data, reqOptions);
            return result;
        } catch (error) {
            
            return error;
        }
    };

    return {
        getForUrl,
        noSessionPost,
        get,
        post,
        put
    };
};

export default useApi;
