const columns = [
    {
        title: 'Sistema',
        dataIndex: 'system',
        key: 'system',
        sorter: (a, b) => a.system.localeCompare(b.system),
    },
    {
        title: 'N° Serie',
        dataIndex: 'serie',
        key: 'serie',
        sorter: (a, b) => a.serie.localeCompare(b.serie),
    },
    {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type',
        sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
        title: 'Certificado',
        dataIndex: 'certificate',
        key: 'certificate',
        sorter: (a, b) => a.certificate.localeCompare(b.certificate),
    },
    {
        title: 'Fecha de caducidad',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
        title: 'Observaciones',
        dataIndex: 'observations',
        key: 'observations',
        sorter: (a, b) => a.observations.localeCompare(b.observations),
    }
    ,
]


const fixtures = () => {
    let fixture = {};
   
    fixture = {
        columns,
    };
  
    return fixture;
  };
  
  export default fixtures;