import React, {useEffect, useState} from "react";
import {Layout, Row, Col, Table, Input, message, Button } from "antd";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { GetDocuments } from "../../hooks/api/documents/documents";
import ModalInvoice from "./components/modal_invoice";
import { motion } from "framer-motion";
import moment from "moment";
import fixtures from './fixtures';
import './styles.css';

const { Content } = Layout;


const Documents = () => {

    const columns = [
        {
            title: 'Folio',
            dataIndex: 'doc_id',
            key: 'doc_id',
            sorter: (a, b) => a.doc_id.localeCompare(b.doc_id),
        },
        {
            title: 'Fecha',
            dataIndex: 'doc_fecha',
            key: 'doc_fecha',
            render: (text) => moment(text).add(6, 'hours').format('DD-MM-YYYY HH:mm A'),
            sorter: (a, b) => moment(a.doc_fecha).unix() - moment(b.doc_fecha).unix(),
        },
        {
            title: 'Total de Factura',
            dataIndex: 'doc_total',
            key: 'doc_total',
            render: (monto) => (
                <span>{`$${monto.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</span>
            ),
            sorter: (a, b) => a.doc_total - b.doc_total,
        },
        {
            title: 'PDF',
            dataIndex: 'doc_id',
            key: 'boton1',
            render: (id) => <Button shape="circle" className="btn_pdf" onClick={() => clickPdf(id)}><FilePdfOutlined /></Button>,
        },
        {
            title: 'XML',
            dataIndex: 'doc_id',
            key: 'boton2',
            render: (id) => <Button shape="circle" className="btn_xml" onClick={() => clickXml(id)}><FileExcelOutlined /></Button>,
        },
    ]

    const {getDocumentsMutation} = GetDocuments();

    const token = sessionStorage.getItem('tokenKronox');
    const [dataFiltered, setDataFiltered] = useState(null);
    const [dataSource, setDataSource] = useState(null);
    const [isOpenInvoice, setIsOpenInvoice] = useState(false)

    useEffect(()=> {
        if (!token) {
            window.location.href = '/login';
        }
        else{
            getDocumentsMutation({},{
                onSuccess: (data) => {
                    if(data !== null && data !== undefined){
                        setDataSource(data)
                        setDataFiltered(data)
                    }
                },
                onError: (error) => {
                    console.log(error)
                }
            })
        }
    }, [token])


    const handleSearch = (value) => {
        const filtered = dataSource.filter((item) =>
            Object.values(item).some((val) => {
            const lowerCaseValue = value.toLowerCase();
            if (typeof val === 'string') {
                return val.toLowerCase().includes(lowerCaseValue);
            } else if (typeof val === 'number') {
                return val.toString().includes(lowerCaseValue);
            }
            return false;
            })
        );
        setDataFiltered(filtered);
    };
    const handleRowClick = (record) => {
        setIsOpenInvoice(true)
    };
    const clickPdf = (id) => {
        console.log(id)
    }
    const clickXml = (id) => {
        console.log(id)
    }


    return(
        <>
            <Content style={{padding:'2%'}}>
                <motion.div className="cardTableTicket" 
                initial={fixtures().cardInitial}
                animate={fixtures().cardAnimated}
                transition={fixtures().cardTransition}
                >
                    <Row style={{marginBottom:10}}>
                        <Col span={18} className="colTitleTableTickets">
                            <span className="titleTableTickets">Mis Documentos</span>
                        </Col>
                    </Row>
                    <Row style={{marginBottom:20}}>
                        <Col xs={{offset:12, span:12}} sm={{offset:12, span:12}} md={{offset:16, span:8}} lg={{offset:18, span:6}} xl={{offset:18, span:6}} className="colSearch">
                            <Input  placeholder="Buscar"  className="search" onChange={e => handleSearch(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{height:''}}>
                            <Table 
                                dataSource={dataFiltered} 
                                columns={columns}  
                                scroll={{x:true}} 
                                pagination={{pageSize:4}}  
                                onRow={(record) => ({
                                    onClick: () => {
                                        handleRowClick(record);
                                    },
                                })}
                                className="tableData"/>
                        </Col>
                    </Row>
                </motion.div>
            </Content>
            <ModalInvoice   key={'modal_invoice'} isOpenInvoice={isOpenInvoice} setIsOpenInvoice={setIsOpenInvoice}/>
        </>
    )
}

export default Documents;