import React, {useEffect, useState} from "react";
import {Row, Col, Input, Form, DatePicker, Select, message} from "antd";
import { PostUser } from "../../../../../../hooks/api/usuarios/postUsuarios";
import moment from "moment/moment";
import { motion } from "framer-motion";
import fixtures from "../../../../../tickets/fixtures";
import './styles.css'

const TabPersonal = ({user}) => {

    const [form] = Form.useForm();

    const {postUser} = PostUser();
    
    const dateFormat = 'YYYY-MM-DD';
    const [fechaNac, setFechaNac] = useState('');
    const generos = [
        { value: 1, label: 'Masculino' },
        { value: 2, label: 'Femenino' },
    ];

    useEffect(() => {
        const fecha = moment(user.usu_fechanac, dateFormat);
        moment(fecha, dateFormat, true).isValid() ? setFechaNac(fecha) : setFechaNac('');
    }, [user.usu_fechanac])

    //FORMATOS DE INPUTS

    const formText = (fieldName, e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[^a-zA-Z]/g, '').toUpperCase();;
    
        // Usar el nombre del campo dinámicamente
        const updatedValues = { [fieldName]: newValue };
        form.setFieldsValue(updatedValues);
    };
    const formNumber = (fieldName, e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[^0-9]/g, '').toUpperCase();;
    
        // Usar el nombre del campo dinámicamente
        const updatedValues = { [fieldName]: newValue };
        form.setFieldsValue(updatedValues);
    };
    const formTextUpper = (fieldName, e) => {
        let newValue = e.target.value;
        newValue = newValue.toUpperCase();;
    
        // Usar el nombre del campo dinámicamente
        const updatedValues = { [fieldName]: newValue };
        form.setFieldsValue(updatedValues);
    };
    
    //VALIDACIONES

    const validateRFC = (_, value) => {
        if (value && value.length < 10 ) {
            return Promise.reject('RFC incompleto');
        }
        else if (value && value.length > 13 ){
            return Promise.reject('RFC incorrecto');
        }
        else if (value  && value.length > 9 && value.length < 13){
            return Promise.reject('Complete la homoclave');
        }
        return Promise.resolve();
    };

    const onChange = (date, dateString) => {
        setFechaNac(dateString)
    };
    const saveUser = (values) => {
        const id = user.usu_id;
        const data = {
                usu_nombres: values.usu_nombres,
                usu_apaterno: values.usu_apaterno,
                usu_amaterno: values.usu_amaterno,
                usu_mail: values.usu_mail,
                usu_tel: values.usu_tel,
                usu_clave: values.usu_clave,
                usu_curp: values.usu_curp,
                usu_fechanac: moment(fechaNac).format('YYYY-MM-DD'),
                usu_rfc: values.usu_rfc,
                usu_genero: values.usu_genero,
                usu_titulo: values.usu_titulo,
        }
        postUser({id, data}, {
            onSuccess: (data) => {
                message.success(data.mensaje);
                let new_user = user;
                user.usu_nombres =  values.usu_nombres;
                user.usu_apaterno= values.usu_apaterno;
                user.usu_amaterno= values.usu_amaterno;
                user.usu_mail= values.usu_mail;
                user.usu_tel= values.usu_tel;
                user.usu_clave= values.usu_clave;
                user.usu_curp= values.usu_curp;
                user.usu_fechanac= fechaNac;
                user.usu_rfc= values.usu_rfc;
                user.usu_genero= values.usu_genero;
                user.usu_titulo= values.usu_titulo;
                sessionStorage.setItem('userKronox', JSON.stringify(new_user))
            },
            onError : (error) => {
                message.error(error);
            }
        })
    }

    return(
        <Form layout="vertical" form={form} initialValues={user} onFinish={saveUser} className="formTabPersonal">
            <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_nombres' label='Nombres(s)' rules={[{ required: true, message: 'Ingrese un nombre' }]}>
                        <Input className="inputTabPersonal" placeholder="Nombres(s)" onChange={(e) => formText('usu_nombres', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_apaterno' label='Apellido Paterno' rules={[{ required: true, message: 'Ingrese un apellido' }]}>
                        <Input className="inputTabPersonal" placeholder="Apellido paterno" onChange={(e) => formText('usu_apaterno', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_amaterno' label='Apellido Materno' rules={[{ required: true, message: 'Ingrese un apellido' }]}>
                        <Input className="inputTabPersonal" placeholder="Apellido materno" onChange={(e) => formText('usu_amaterno', e)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_mail' label='Correo' rules={[{ required: true, message: 'Ingrese un correo' }]}>
                        <Input className="inputTabPersonal" placeholder="Correo" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_genero' label='Genero' rules={[{ required: true, message: 'Ingrese un genero' }]}>
                            <Select
                                placeholder="Selecciona una genero"
                                dropdownAlign='left'
                                optionFilterProp="children"
                                options={generos}
                            />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item label='Fecha de Nacimiento' rules={[{ required: true, message: 'Ingrese una fecha' }]}>
                        <DatePicker defaultValue={fechaNac !== '' ? fechaNac: null} onChange={onChange} style={{display:'block'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_curp' label='CURP' rules={[{ required: true, message: 'Ingrese una CURP' }]}>
                        <Input className="inputTabPersonal" placeholder="CURP" onChange={(e) => formTextUpper('usu_curp', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_rfc' label='RFC' rules={[{ required: true, message: 'Ingrese un RFC' }, {validator: validateRFC}]}>
                        <Input className="inputTabPersonal" placeholder="RFC" onChange={(e) => formTextUpper('usu_rfc', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_clave' label='Clave' rules={[{ required: true, message: 'Ingrese una clave' }]}>
                        <Input className="inputTabPersonal" placeholder="Clave" onChange={(e) => formTextUpper('usu_clave', e)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_titulo' label='Titulo' rules={[{ required: true, message: 'Ingrese un titulo' }]}>
                        <Input className="inputTabPersonal" placeholder="Titulo" onChange={(e) => formText('usu_titulo', e)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="colInputSm">
                    <Form.Item name='usu_tel' label='Teléfono' rules={[{ required: true, message: 'Ingrese un teléfono' }]}>
                        <Input className="inputTabPersonal" placeholder="Teléfono" onChange={(e) => formNumber('usu_tel', e)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginBottom:10}}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{padding:'2%'}}>
                    <motion.button whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                            type='submit'  className='btn-Primary btnSendTicket'>
                            Enviar
                    </motion.button>
                </Col>
            </Row>
        </Form>
    )
}

export default TabPersonal;