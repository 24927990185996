export const  TicketStatus  = Object.freeze({
    TODOS: 0,
    TICKET_LIBRE: 1,
    TICKET_SEGUIMIENTO: 2,
    ESPERA_CLIENTE: 3,
    CERRADO_CON_EXITO: 4,
    CERRADO_POR_CLIENTE: 5,
    CERRADO_SIN_EXITO: 6,
    ESPERA_FABRICANTE: 7,
    CADUCADO: 9,
})

export const  TicketIncidencia = Object.freeze( {
    NOTA_RAPIDA : 1,
    TICKET_TOMADO : 2,
    SEGUIMIENTO : 3,
    PIDIENDO_APOYO : 4,
    PIDIENDO_APOYO_A_TODOS : 5,
    CERRANDO_TICKET : 6,
    TICKET_CERRADO : 7,
    ENCUESTA_CONTESTADA : 8,
    TIEMPO_EXCEDIDO : 9,
    REASIGNACION_DE_TICKET : 10,
    CAMBIO_ACUERDO_SERVICIO : 11
})