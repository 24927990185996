import React, {useState, useEffect} from "react";
import { motion } from "framer-motion";
import { Row, Col, Avatar, Progress, message } from "antd";
import ModalEncuesta from "../modalEncuesta/modalEncuesta";
import fixtures from "../../fixtures";
import './styles.css'



const TicketCard = ({id, clave, status, paso,  pasoText, img, nota, atencion, cierre, encuesta, sistema, chatId, activo}) => {

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenEncuesta, setIsOpenEncuesta] = useState(false);
    const [openChat, setOpenChat] = useState(false);

    useEffect(()=>{
        if(atencion === true){
            const isClose = status.toLowerCase().includes('cerrado');
            window.location.href=`/ticket_info?id=${id}&folio=${clave}&close=${isClose}&active=${activo}`
        }
    },[atencion, clave, id, activo])

    const goSeguimiento = () => {
        const isClose = status.toLowerCase().includes('cerrado');
        window.location.href=`/ticket_info?id=${id}&folio=${clave}&close=${isClose}&active=${activo}`
    }
    const openCloseModal = () => {
        setIsOpenModal(true)
    }
    const openEncuestaModal = () => {
        setIsOpenEncuesta(true)
    }
    const clickChat = () => {
        chatId !== null ? setOpenChat(true) : message.error('No se pudo acceder al chat')
    }


    return(
        <motion.div className="ticketCard" 
            initial={fixtures().cardInitial}
            animate={fixtures().cardAnimated}
            transition={fixtures().cardTransition}
            >
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={18} className="colClave">
                                <span className="claveTicket">{clave}</span><span className="statusTicket">{`(${status})`}</span>
                            </Col>
                            <Col span={6} className="colImg">
                                <Avatar shape="square" size={64} src={img} />
                            </Col>
                        </Row>
                        <Row style={{marginTop:-35}}>
                            <Col span={18} className="colAsignacion"><span className="asignacionTicket">Sistema:{sistema.sis_nombre}</span></Col>
                        </Row>
                        {nota !== null ? (
                            <Row style={{marginTop:30}}>
                            <Col span={24} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                <span className="textPaso" style={{fontSize:16}}>Nota:</span>
                            </Col>
                            <Col span={24} style={{display:'flex', justifyContent:'flex-start', alignItems:'center', marginTop:5}}>
                                <span className="textPaso">
                                    {nota}
                                </span>
                            </Col>
                        </Row>
                        ) : null}
                        <Row style={{marginTop:30}}>
                            <Col span={24} className="colPaso">
                                <span className="textPaso">{pasoText}</span>
                            </Col>
                            <Col span={24} className="colBar">
                                <Progress percent={paso} showInfo={false} status="active" strokeColor={paso === 100 ? { from: '#ff6767', to: '#eb0707' }: { from: 'rgba(149,232,246,1)', to: '#44adab93' }} />
                            </Col>
                        </Row>
                        <Row style={{marginTop:30}}>
                            <Col span={12} className="colBtnSeguimiento">
                                <motion.button 
                                    whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                                    onClick={goSeguimiento}
                                    type='submit'  className='btn-Primary btnSeguimiento'>
                                                Seguimiento
                                </motion.button>
                            </Col>
                            {cierre ? (
                                <Col span={12} className="colBtnClose">
                                    <motion.button 
                                        whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                                        onClick={openCloseModal}
                                        type='submit'  className='btn-Primary btnSeguimiento'>
                                        Contestar encuesta
                                    </motion.button>
                                </Col>
                            ):null}
                            {/* {encuesta ? (
                                <Col span={12} className="colBtnClose">
                                    <motion.button 
                                        whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                                        onClick={openEncuestaModal}
                                        type='submit'  className='btn-Primary btnSeguimiento'>
                                        Contestar encuesta2
                                    </motion.button>
                                </Col>
                            ):null} */}
                        </Row>
                    </Col>
                </Row>
                {/* <ModalClose isModalOpen={isOpenModal} setModalOpen={setIsOpenModal} idTicket={id} isHome={true}/> */}
                <ModalEncuesta isModalOpen={isOpenModal} setModalOpen={setIsOpenModal} idTicket={id} isHome={true}/>
        </motion.div>
    )

}


export default TicketCard;