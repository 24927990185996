import React, {useState, useEffect} from "react";
import { Row, Col, Avatar, Tag, Card, Carousel, message } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import { GetAsesor } from "../../../../hooks/api/tickets/getAsesor";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import logoDefatult from '../../../../assets/imgs/logoDefault.png'
import './styles.css'
import { Rating } from "@mui/material";

const CardAsesor = ({isOpen}) => {

    const {getAsesorMutation} = GetAsesor();
    const token = sessionStorage.getItem('tokenKronox');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [id, setId] = useState(queryParams.get('id'));
    const [agente, setAgente] = useState(null)
    const competencias = [
        {
            "competencia": "Factura Electrónica",
            "tiene": true
        },
        {
            "competencia": "Contabilidad",
            "tiene": true
        },
        {
            "competencia": "Comercial Premium",
            "tiene": true
        },
        {
            "competencia": "Comercial Pro",
            "tiene": true
        },
        {
            "competencia": "Punto de venta",
            "tiene": false
        },
        {
            "competencia": "Nominas",
            "tiene": true
        },
        {
            "competencia": "Bancos",
            "tiene": true
        },
        {
            "competencia": "Producción",
            "tiene": false
        },
        {
            "competencia": "Contabiliza",
            "tiene": true
        }
    ]
    const coloresAptitudes = ["cyan", "green", "volcano", "orange", "gold"];

    useEffect(()=> {
        if (!token) {
            window.location.href = '/login';
        }
        else{
            getAgente();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAgente = () => {
        getAsesorMutation(id,
            {
                onSuccess: (data) => {
                    if(data !== null){
                        setAgente(data)
                    }
                },
                onError: (error) =>{
                    message.error(error)
                }
        })
    }

    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return(
        <AnimatePresence>
            {isOpen && (
                <motion.div
                className="cardAsesor"
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                >
                {agente !== null ? (
                    <>
                        <Row style={{marginTop:10}}>
                            <Col span={4}>
                                <Avatar size={64} src={logoDefatult} />
                            </Col>
                            <Col span={20} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Row>
                                    <Col span={24}><span className="nameAsesor">{`${capitalizeFirstLetter(agente.usu_nombres)} ${capitalizeFirstLetter(agente.usu_apaterno)} ${capitalizeFirstLetter(agente.usu_amaterno)}`}</span></Col>
                                    <Col span={24}><Rating name="customized-10" style={{fontSize:15, color:'#44adab', marginTop:3}} defaultValue={2}  disabled max={5} /></Col>
                                    {agente.aptitudes.length > 0?(
                                        <>
                                            {agente.aptitudes.map((aptitud, index) => (
                                                <Col span={24} key={index}>
                                                    <Tag color={coloresAptitudes[index]} style={{marginTop:5}}>{aptitud.apti_nombre}</Tag>
                                                </Col>
                                            ))}
                                        </>
                                    ):( <Col span={24}>
                                        <p className="tagNoAptitud">Sin aptitudes registradas</p>
                                        </Col>
                                    )}
                                    
                                </Row>
                                
                            </Col>
                        </Row>
                        <Row style={{marginTop:'30px'}}>
                            <Col span={12}>
                                <Row>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <span className="tickets">Experiencia</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <span className="tickets">3 años 9 Meses</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <span className="tickets">Tickets resueltos</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <span className="tickets">667</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{marginTop:'25px'}}>
                            <Col span={14}></Col>
                            {agente.certificaciones.length>0?(<Col span={8} className="titleCompetencia">Competencia</Col>):null}
                        </Row>
                        <Row style={{marginTop:'15px'}}>
                            {agente.certificaciones.length>0 ? (
                                <>
                                    {agente.certificaciones.map((certificado, index) => (
                                        <Row key={index} style={{width:'100%'}}>
                                            <Col span={12} className="titleCompetencia">{certificado.usce_titulo}</Col>
                                            <Col span={12} style={{textAlign:'center'}} ><CheckOutlined style={{color:'#02FF3A'}} /></Col>
                                        </Row>
                                    ))}
                                </>
                            ):
                            (
                                <Col span={24}>
                                        <p className="tagNoAptitud">Sin certificaciones registradas</p>
                                </Col>
                            )}
                        </Row>
                        <Row style={{marginTop:'25px'}}>
                            <Col span={24} className="colCarrousel">
                                <Carousel autoplay className="carous" dots={false}>
                                    <div>
                                        <Card className="cardOpinion">
                                            <Row>
                                                <Col span={8} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                                    <Avatar size={64} src={logoDefatult} />
                                                </Col>
                                                <Col span={16}>
                                                    <Row>
                                                        <Col span={24} style={{textAlign:'start'}}>
                                                            <span className="nameOpinion">María del Carmen Solís Rua </span>
                                                        </Col>
                                                        <Col span={24} style={{marginTop:'-8px',textAlign:'start'}}>
                                                            <span className="companyOpinion">Lubricantes y derivados S.A. de C.V. </span>
                                                        </Col>
                                                        <Col span={24} style={{marginTop:'0px', fontSize:5, textAlign:'start', display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
                                                            <Rating name="customized-10" style={{fontSize:15}} defaultValue={3} max={5} />
                                                        </Col>
                                                        <Col span={24} style={{marginTop:'0px', fontSize:10, textAlign:'justify',color:'white'}}>
                                                            <span>Los felicito por el servicio, el técnico logro resolver mi problema de facturación.</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                    <div>
                                        <Card className="cardOpinion">
                                            <Row>
                                                <Col span={8} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                                    <Avatar size={64} src={logoDefatult} />
                                                </Col>
                                                <Col span={16}>
                                                    <Row>
                                                        <Col span={24} style={{textAlign:'start'}}>
                                                            <span className="nameOpinion">María del Carmen Solís Rua </span>
                                                        </Col>
                                                        <Col span={24} style={{marginTop:'-8px',textAlign:'start'}}>
                                                            <span className="companyOpinion">Lubricantes y derivados S.A. de C.V. </span>
                                                        </Col>
                                                        <Col span={24} style={{marginTop:'0px', fontSize:5, textAlign:'start', display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
                                                            <Rating name="customized-10" style={{fontSize:15}} defaultValue={3} max={5} />
                                                        </Col>
                                                        <Col span={24} style={{marginTop:'0px', fontSize:10, textAlign:'justify',color:'white'}}>
                                                            <span>Los felicito por el servicio, el técnico logro resolver mi problema de facturación.</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                    <div>
                                        <Card className="cardOpinion">
                                            <Row>
                                                <Col span={8} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                                    <Avatar size={64} src={logoDefatult} />
                                                </Col>
                                                <Col span={16}>
                                                    <Row>
                                                        <Col span={24} style={{textAlign:'start'}}>
                                                            <span className="nameOpinion">María del Carmen Solís Rua </span>
                                                        </Col>
                                                        <Col span={24} style={{marginTop:'-8px',textAlign:'start'}}>
                                                            <span className="companyOpinion">Lubricantes y derivados S.A. de C.V. </span>
                                                        </Col>
                                                        <Col span={24} style={{marginTop:'0px', fontSize:5, textAlign:'start', display:'flex',justifyContent:'flex-start', alignItems:'center'}}>
                                                            <Rating name="customized-10" style={{fontSize:15}} defaultValue={3} max={5} />
                                                        </Col>
                                                        <Col span={24} style={{marginTop:'0px', fontSize:10, textAlign:'justify',color:'white'}}>
                                                            <span>Los felicito por el servicio, el técnico logro resolver mi problema de facturación.</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </Carousel> 
                            </Col>
                        </Row>
                    </>
                ):null}
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default CardAsesor;