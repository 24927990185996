import React, {useState, useEffect} from "react";
import {Modal, Col, Row, Avatar} from 'antd';
import Visualizador from "./components/visualizador/visualizador";
import './styles.css'


const ModalAdjuntos = ({adjuntos, isOpen, setOpen}) => {
    
    const [fileOpen, setFileOpen] = useState(false);
    const [urlFile, setUrlFile] = useState(null);
    const [mimeFile, setMimeFile] = useState(null);
    const [nameFile, setNameFile] = useState(null);

    const openModal = (url, mime, name) => {
        setUrlFile(url);
        setMimeFile(mime)
        setFileOpen(true);
        setNameFile(name)
    }


    return(
        <Modal  open={isOpen} closable={false} footer={null} >
            <Row>
                <Col span={24} style={{display:'flex', justifyContent:'flex-end'}}>
                    <span onClick={()=>setOpen(false)} className='closeModalPass'>x</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}  style={{display:'flex', justifyContent:'center'}}>
                    <span className='titleModalMss'>Archivos Adjuntos del Ticket</span>
                </Col>
            </Row>
            <Row>
                {adjuntos !== null ? 
                    (
                    <>
                    {adjuntos.every(adjunto=>adjunto===null) ?
                    (
                        <Col span={24}  style={{display:'flex', justifyContent:'center'}}>
                            <p className="nofiles">No existen archivos adjuntos</p>
                        </Col>
                    ):
                    (
                        <>
                            {adjuntos.map((adjunto, index) => (
                                <Col span={24} key={index} >
                                    {adjunto !== null ? (
                                        <Row style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:20}}>
                                            <Col span={4} style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                                                <Avatar  className='iconNumber' >{adjunto.key}</Avatar>
                                            </Col>

                                            <Col span={20} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <Row>
                                                    {adjunto.recursos.map((file, index)=>(
                                                        <>
                                                            <Col span={24} key={index} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                                <span onClick={()=>{openModal(file.recu_ruta, file.recu_mime, file.recu_nombre)}} className="nameFiles">{file.recu_nombre}</span>
                                                            </Col>
                                                            
                                                        </>
                                                    ))}
                                                </Row>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Col>
                            ))}
                        </>
                    )}
                    </>
                    ) : 
                    (
                        <Col span={24}  style={{display:'flex', justifyContent:'center'}}>
                            <p className="nofiles">No existen archivos adjuntos</p>
                        </Col>
                    )
                }
            </Row>
            <Visualizador  key={'visualizador'} url={urlFile} mime={mimeFile} isOpen={fileOpen} setOpen={setFileOpen} name={nameFile}/>
        </Modal>
    )

}

export default ModalAdjuntos;