import useApi from "../../../hooks/api/useApiRequest";
import { useMutation } from "react-query";

export const GetTicket = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingTicket} params el tipo {@link MutatingTicket} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (id) => {
        const data  = await api.get(`/clientes/tickets/${id}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTicketMutation: mutation.mutate,
    };
}