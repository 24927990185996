import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { motion } from "framer-motion";
import moment from 'moment/moment';

const useStyles = createUseStyles({
    chatMessage: {
        width: "100%",
        display: "flex",
        marginBottom: 10,
        flexDirection: "column",
        userSelect: "none",
        "& .message-content": {
            padding: 10,
            maxWidth: "48%",
            wordBreak: "break-word",
            "&.sent": {
                borderRadius: "10px 10px 0 10px",
                // background: theme.primaryColor,
                background: "#44adab",
                color: "white"
            },
            "&.received": {
                border: "1px solid #44adab",
                borderRadius: "10px 10px 10px 0",
                background: "#fff",
            }
        },
        "& .message-meta": {
            color: "#bbb"
        },
        "& .time-chat": {
            color: "#7e8383",
            fontFamily: "Lato, sans-serif",
            fontSize:10
        },
    }
});


const ChatMessage = ({ type, text, date, chatId, user, key }) => {
    const styles = useStyles();
    const [showDate, setShowDate] = useState(false);

    const handleClickMessage = () => {
        setShowDate(!showDate)
    }

    return (
        <>
        <motion.div
            // layout
            className={`${styles.chatMessage} chatmessage-${chatId}`}
            style={{
                alignItems: type === "received" ? "start" : "end"
            }}
            onClick={handleClickMessage}
            key={key}
        >
            <div className={`message-content ${type}`}>{text}</div>
            {
                user &&
                <div className='message-meta'>{user?.usu_nombres}</div>
            }
            <div className='container-date'>
                <span className='time-chat'>{moment(date).format("DD/MM/YYYY HH:MM")}</span>
            </div>
        </motion.div>
        
        </>
    );
}

export default ChatMessage;
