
export const  ChatConversacionCanal  = Object.freeze({
    INTERNO: 1,
    WHATSAPP: 2,
    OTRO: 3
})

export const  ChatConversacionTipo  = Object.freeze({
    GENERAL: 1,
    SEGUIMIENTO: 2,
})

export const EMessageType = Object.freeze({
    RECEIVED: "received",
    SENT: "sent"
})


