import React, {useEffect,useState} from "react";
import {Row, Col, Avatar, message } from "antd";
import { GetTicket } from "../../../../hooks/api/tickets/getTicket";
import ModalClose from "../modalClose/modalClose";
import ModalEncuesta from "../modalEncuesta/modalEncuesta";
import { calculaTiempoTranscurridoServicio } from "../../../../helpers/timerTicket";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import fixtures from "../../fixtures";
import './styles.css';

const CardTimer = () => {

    const {getTicketMutation} = GetTicket();

    const token = sessionStorage.getItem('tokenKronox');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [id, setId] = useState(queryParams.get('id'));
    const [time, setTime] = useState(null);
    const [status, setStatus] = useState(null)
    const [isCaducado, setIsCaducado] = useState(false)
    const [isRunning, setIsRunning] = useState(true);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenEncuesta, setIsOpenEncuesta] = useState(false);
    const [leaveTicket, setLeaveTicket] = useState(false);

    useEffect(()=> {
        if (!token) {
            window.location.href = '/login';
        }
        else{
            getTime();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        const intervalId = setInterval(getTime, 500);
        return () => {clearInterval(intervalId);};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaveTicket]);
    useEffect(()=> {
            const timer = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        return () => {clearInterval(timer);};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    const getTime = () => {
        getTicketMutation(id,
            {
                onSuccess: (data) => {
                    if(data !== undefined){
                        data.status.stat_id === 9 ? setIsCaducado(true) : setIsCaducado(false);
                        if(data.status.stat_id === 1 || data.status.stat_id === 6 || data.status.stat_id === 5 || data.status.stat_id === 3 || data.status.stat_id === 7 || data.incidencias[data.incidencias.length-1].tipi_id === 8){
                            setStatus(data.status.stat_nombre)
                            setIsOpenModal(false)
                            setIsOpenEncuesta(false)
                            if((data.status.stat_id === 1 || data.status.stat_id === 6 || data.status.stat_id === 5) && leaveTicket){
                                setLeaveTicket(false);
                                window.location.href=`/ticketsAdministracion`;
                            }
                        }else if(data.incidencias[data.incidencias.length-1].tipi_id === 6){
                            setStatus(data.incidencias[data.incidencias.length-1].status.stat_nombre);
                            setIsOpenModal(true)
                            setIsOpenEncuesta(false)
                        }else if(data.incidencias[data.incidencias.length-1].tipi_id === 7){
                            setStatus(data.incidencias[data.incidencias.length-1].tici_nombre)
                            setIsOpenModal(false)
                            setIsOpenEncuesta(true)
                        }
                        else{
                            setIsOpenModal(false)
                            setIsOpenEncuesta(false)
                            const incidencias = data.incidencias;
                            const seconds = calculaTiempoTranscurridoServicio(incidencias);
                            setTime(seconds.segundosTranscurridosServicio);
                            setStatus(null)
                            setLeaveTicket(true);
                        }
                    }
                },
                onError: (error) =>{
                    message.error(error)
                }
        })
    }
    const formatTime = (time) => {
        const dias = Math.floor(time / 86400);
        time %= 86400;
        const horas = Math.floor(time / 3600);
        time %= 3600;
        const minutos = Math.floor(time / 60);
        time %= 60;
    
        return `${padZero(dias)} D :${padZero(horas)} H :${padZero(minutos)} M :${padZero(time)} S`;
    };
    const padZero = (value) => {
        return value.toString().padStart(2, '0');
    };

    return (
        <motion.div className="cardSeguimiento" 
                initial={fixtures().cardInitial}
                animate={fixtures().cardAnimated}
                transition={fixtures().cardTransition}
                >
                    <Row>
                        <Col span={24}>
                            {status  !== null ? (
                                <span className="closeLabel">{status}</span>
                            ): (<span className={isCaducado ? "time caducado" : "time"}>{time !== null && formatTime(time)}</span>)}
                        </Col>
                    </Row>
            {/* <ModalClose isModalOpen={isOpenModal} setModalOpen={setIsOpenModal} idTicket={null} isHome={false}/> */}
            <ModalEncuesta isModalOpen={isOpenModal} setModalOpen={setIsOpenModal} idTicket={null} isHome={false}/>
        </motion.div>
    )

}


export default CardTimer;