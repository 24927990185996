import React, {useRef, useState} from "react";
import {Form, Row, Col, Input, Button, message } from 'antd';
import { CheckOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { PostSendCodeRegister } from "../../../../hooks/api/register/sendCode";
import { RegisterUser } from "../../../../hooks/api/register/registerUser";
import fixtures from "../../fixtures";
import './styles.css'

const CodeVerification = ({formData, setCodeSend}) => {

    const {postSendCodeRegisterMutation} = PostSendCodeRegister();
    const {mutateRegisterUser} = RegisterUser();

    const inputRefs = useRef([]);
    const [formSend, setFormSend] = useState(false)

    const handleInputChange = (index, e) => {
        const value = e.target.value;
    
        // Si se ingresó un número y no está vacío, avanzar al siguiente input
        if (value  && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    
        // Si se borró el valor y no está en el primer input, retroceder al input anterior
        if (!value && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };
    const handleInputKeyDown = (index, e) => {
        // Si se presionó la tecla de retroceso y el input está vacío, retroceder al input anterior
        if (e.key === 'Backspace' && e.target.value === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };
    const handleInputPaste = (e) => {
        e.preventDefault(); // Evitar pegar el valor completo
    
        const pastedData = e.clipboardData.getData('text/plain');
        const digits = pastedData // Obtener solo los dígitos
        digits.split('').forEach((digit, index) => {
          if (index < inputRefs.current.length) {
            inputRefs.current[index].value = digit;
            inputRefs.current[index].focus();
          }
        });
    };
    const createInputRefs = (ref, index) => {
        inputRefs.current[index] = ref;
    };
    const resendCode = () => {
        let dataSendCode = {
            mail: formData.email,
            name: formData.name
        }
        postSendCodeRegisterMutation(dataSendCode,
            {
                onSuccess: (data) => {
                    if(data.success){
                        message.success(data.mensaje);
                    }else{
                        message.error(data.mensaje);
                    }  
                    
                },
                onError: (error) =>{
                    message.error(error)
                }
            }
        )
    }
    const onFinish = (values) => {
        let dataUser = {
            usu_mail: formData.email,
            usu_nombres: formData.name,
            usu_apaterno: formData.apellidoP,
            usu_amaterno: formData.apellidoM,
            usu_tel: formData.number,
            codigo: Object.values(values).join('')
        }
        mutateRegisterUser(dataUser,
            {
                onSuccess: (data) => {
                    if(data.data.success){
                        sessionStorage.setItem('tokenKronox', data.data.data.access_token);
                        sessionStorage.setItem('userKronox', JSON.stringify(data.data.data.usuario));
                        setFormSend(true)
                        setTimeout(() => {
                            window.location.href = '/ticketsAdministracion'; 
                        }, 1200);
                    }else{
                        message.error('Hubo un error al registrar al usuario')
                    }
                    
                },
                onError: (error) =>{
                    message.error(error)
                }
            }
        )
        
    };

    return(
        <Form onFinish={onFinish} className="formCodeVerification">
            <Row>
                <Col span={24} style={{textAlign:'justify', lineHeight:1.2}}>
                    <span className="instructionCode">
                        Te hemos enviado un código de verificación a tu correo electrónico <span className="emailInst">{formData.email}</span> por parte de Kronox & Kairós, 
                        si no esta en tu buzón de entrada, revisa tus carpetas de no deseados, una vez encontrado por favor 
                        introduce el código de 6 dígitos a continuación.
                    </span>
                </Col>
            </Row>
            <Row style={{marginTop:10}}>
                <Col span={18} className="colTextCode">
                    <span className="textCode">Código enviado al correo</span>
                </Col>
                <Col span={6} className="colResend">
                    <Button type="link" className="resend" onClick={resendCode}>Reenviar</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="colInputsTwo">
                    {[...Array(6)].map((_, index) => (
                        <Form.Item name={index} key={index} className="inputItem"  rules={[{required:true, message: ''}]}>
                            <Input 
                                className="inputsCode"
                                maxLength={1}
                                onChange={(e) => handleInputChange(index, e)}
                                onKeyDown={(e) => handleInputKeyDown(index, e)}
                                onPaste={(e) => handleInputPaste(e)}
                                ref={(ref) => createInputRefs(ref, index)}
                            />
                        </Form.Item>
                        
                    ))}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <motion.button 
                            whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                            animate={formSend ? fixtures().succesBtn : fixtures().initialBtn}
                            type='submit'  className='btn-Primary btnValidar'>
                            {formSend? <CheckOutlined /> : 'Validar'}
                    </motion.button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <motion.button 
                            whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                            type='submit'  className='btn-Primary btnBack' onClick={()=>setCodeSend(false)}>
                            Volver
                    </motion.button>
                </Col>
            </Row>
        </Form>
    )
}

export default CodeVerification;