import CONFETI from "./confeti.json";
import FUEGOS_ARTIFICIALES from "./fuegos_artificiales.json";
import CHART_MONEY from "./chart_money.json";
import MESSAGES from "./messages.json";
import PHONE_COMUNICATION_3 from "./phone_comunication_3.json";
import ADMINISTRATION_CHARTS from "./administration_charts.json";
import CHAT_CONVERSATION from "./chat_conversation.json";

export const LOTTIE_ANIMATIONS = Object.freeze({
    CONFETI,
    FUEGOS_ARTIFICIALES,
    CHART_MONEY,
    MESSAGES,
    PHONE_COMUNICATION_3,
    ADMINISTRATION_CHARTS,
    CHAT_CONVERSATION
});