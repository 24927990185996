import React, {useState, useEffect} from "react";
import { GetUserID } from "../../hooks/api/usuarios/getUsuario";
import { Affix, Layout, Row, Col, Avatar } from "antd";
import { useSelector } from "react-redux";
import ImgDefault from '../../assets/imgs/logoDefault.png'
import './styles.css';

const {Footer} = Layout;

const FooterLayout = () => {

    const {getUserIDMutation} = GetUserID();

    const user = JSON.parse(sessionStorage.getItem('userKronox'))
    const [seller, setSeller] = useState(null)
    const [bottom, setBottom] = useState(0);

    const { apiHost } = useSelector((state) => ({
        apiHost: state.apiHost,
    }));

    useEffect(()=> {
        getSeller();
    }, []);

    const getSeller = () => {
        if(user.usuarios_empresas?.length > 0) {
            const company = user.usuarios_empresas.some(comp => comp.use_principal === true) ?
                user.usuarios_empresas.filter(comp => comp.use_principal === true)[0].empresa :
                user.usuarios_empresas[0].empresa;
            if(company.emp_vendedor !== null){
                getUserIDMutation({id:company.emp_vendedor}, {
                    onSuccess: (data) => {
                        if(data.success){
                            const sellerData = {
                                name: `${capitalizeFirstLetter(data.data.usu_nombres)} ${capitalizeFirstLetter(data.data.usu_apaterno)} ${capitalizeFirstLetter(data.data.usu_amaterno)}`,
                                email: data.data.usu_mail,
                                phone: data.data.usu_tel,
                                // img: ImgDefault
                                img: data.data.recursos.length > 0 ? `http://192.168.100.22:3001/api/static/${data.data.recursos[0].recu_ruta}` : ImgDefault
                            }
                            setSeller(sellerData);
                        }else setSeller(null);
                    },
                    onError: (error) => {
                        console.log(error)
                        setSeller(null);
                    }
                })
            }else setSeller(null);

        }else setSeller(null);
    }

    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }


    return(
            <Affix offsetBottom={0} style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 1, transition: 'bottom 0.3s' }} >
                <Footer className="footerLayout">
                    <Row className="rowOneFooter">
                        <Col sm={24} md={24} lg={24} xl={20} xxl={20} style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
                            <Row style={{width:'100%', alignItems:'center'}}>
                                {seller !== null ? (
                                    <Col xs={24} sm={14} md={11} lg={10} xl={10} xxl={8} style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
                                        <Row style={{width:'100%', alignItems:'center'}}>
                                            <Col xs={4} sm={4} md={5} lg={4} xl={4} xxl={3} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                                <Avatar src={seller.img} size={45} style={{border: '1px solid rgba(1,150,198,1)'}} />
                                            </Col>
                                            <Col xs={14} sm={15} md={15} lg={14} xl={14} xxl={13} className="columnDataSeller" >
                                                <span className="dataSeller">{seller.name}</span>
                                                <span className="dataSeller">{seller.email}</span>
                                                <span className="dataSeller">{seller.phone}</span>
                                            </Col>
                                            <Col span={4} className="columnTitleSeller">
                                                <span className="titleSeller">Asesor de Ventas</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                ):null}
                            </Row>
                        </Col>
                        <Col sm={0} md={0} lg={0} xl={4} xxl={4}></Col>
                    </Row>
                </Footer>
            </Affix> 
    )

}

export default FooterLayout;