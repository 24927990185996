import React from 'react';
import {Form, Row, Col, Input, message } from 'antd';
import {LockOutlined} from '@ant-design/icons'
import { motion } from 'framer-motion';
import { UseLogin } from '../../../hooks/api/login';
import './styles.css'
import fixtures from '../fixtures';

const IngressCard = ( {goRegister} ) => {

    const {mutateLogin} = UseLogin();

    const onFinish = (values) => {
        mutateLogin({
            "username": values.email,
            "password": values.password
            },
            {
                onSuccess: (data)=>{
                    if(data.access_token !== ''){
                        sessionStorage.setItem('tokenKronox', data.access_token);
                        sessionStorage.setItem('userKronox', JSON.stringify(data));
                        if(JSON.parse(localStorage.getItem('hideGuideAuto')) === null) localStorage.setItem('hideGuideAuto', false)
                        window.location.href = '/ticketsAdministracion';
                        const id_paquete = sessionStorage.getItem('id_paquete');
                        if (id_paquete) {
                            sessionStorage.removeItem('id_paquete')
                        }
                    }else{
                        message.error("Usuario y/o contraseña incorrecto")
                    }
                },
                onError: () =>{
                    message.error("Usuario y/o contraseña incorrecto")
                }
            }
        )
    };
    const clickRegister = () => {
        window.location.href = '/registro';
    }
    const clickForget = () => {
        window.location.href = '/recuperar_password';
    }


    return(
        <motion.div className='cardLogin' initial={fixtures().cardLoginInitial} animate={fixtures().cardLoginAnimate} transition={fixtures().cardLoginTransition}>
            <Row>
                <Col span={24} >
                    <span className='titleLogin'>Sistema Kronox</span>
                </Col>
            </Row>
            <Row>
                <Form onFinish={onFinish} style={{width:'100%', marginTop:20, padding:'0% 8%'}}>
                    <Col span={24}>
                        <Form.Item name='email' rules={[{required:true, message: 'Ingrese un correo'}]}>
                            <Input className='inputCredential' placeholder='Correo' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='password' rules={[{required:true, message: 'Ingrese una contraseña'}]}>
                            <Input.Password className='inputCredential' placeholder='Contraseña' />
                        </Form.Item>
                    </Col>
                    <Col span={24} className='colForgetPass'>
                            <motion.span onClick={clickForget} style={{cursor:'pointer'}}
                                initial={fixtures().initialForgetPass} 
                                whileHover={fixtures().hoverForgetPass} 
                                whileTap={fixtures().tapForgetPass} >
                                <LockOutlined /> ¿Olvidaste tu contraseña?
                            </motion.span>
                    </Col>
                    <Col span={24}>
                        <motion.button whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                            type='submit'  className='btn-Primary btnIngresar'>
                            Ingresar
                        </motion.button>
                    </Col>
                    <Col span={24} className='colGoRegister'>
                            <p className='textGoRegiter'>¿No tienes cuenta?</p> <p className='goRegister' onClick={clickRegister}>Regístrate</p>
                    </Col>
                    <Col span={24}>
                        <a href={require('../../../assets/pdfs/aviso_privacidad.pdf')} target="_blank"  rel="noopener noreferrer" className='avisoPrivacidad'>Aviso de privacidad</a>
                    </Col>
                </Form>
            </Row>
        </motion.div>
    )
}


export default IngressCard;