import useApi from "../useApiRequest";
import { useMutation } from "react-query";

export const PostValidatCode = () => {
    const api = useApi();

    /**
     * 
     * @param {MutatingValidatCode} params el tipo {@link MutatingValidatCode} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (params) => {
            const data  = await api.noSessionPost(`/clientes/recuperacion/code/validate`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        postValidatCodeMutation: mutation.mutate,
    };
};