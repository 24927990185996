
const hardEmpresas = [
    {
        value: 1,
        label: 'Empresa 1'
    },
    {
        value: 2,
        label: 'Empresa 2'
    },
]
const hardLicencias = [
    {
        value: 1,
        label: 'Licencia 1'
    },
    {
        value: 2,
        label: 'Licencia 2'
    },
]
const ticketsWaiting = [
    {
        value: 1,
        label: 'Ticket20230808862'
    },
    {
        value: 2,
        label: 'Ticket20230808963'
    },
]
const ticketsNext = [
    {
        value: 1,
        label: 'Ticket20230808864'
    },
    {
        value: 2,
        label: 'Ticket20230808965'
    },
    {
        value: 2,
        label: 'Ticket20230808966'
    },
]
const hoverbtnLogin = { scale: 1.05,}
const tapbtnLogin = { scale: 0.9,}
const cardInitial = { opacity: 0, scale: 0.5 };
const cardAnimated = { opacity: 1, scale: 1 };
const cardTransition = {duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01]};

const fixtures = () => {
    let fixture = {};
   
    fixture = {
        hardEmpresas,
        hardLicencias,
        hoverbtnLogin,
        tapbtnLogin,
        cardInitial,
        cardAnimated,
        cardTransition,
        ticketsWaiting,
        ticketsNext
    };
  
    return fixture;
  };
  
  export default fixtures;