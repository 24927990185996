import useApi from "../useApiRequest";
import { useMutation } from "react-query";


export const CheckEmail = (options) => {
    const api = useApi();


    /**
     * 
     * @param {MutatingLogin} params el tipo {@link MutatingLogin} se utilizará para las acciones create y update
     * returns 
    */
    const mutate = async (params) => {
            const data  = await api.getForUrl(`/registro/mailcheck/${params.email}`);
            return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        mutateEmail: mutation.mutate,
    };
};