import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../views/login/login';
import Tickets from '../views/tickets/tickets';
import TablaTicket from '../views/tickets/tablaTickets/tablaTickets'
import TicketsInfo from '../views/tickets/ticketInfo/ticketInfo';
import Profile from '../views/profile/profile';
import Payments from '../views/payments/payments';
import Purchase from '../views/payments/purchase/purchase';
import Documents from '../views/documents/documents';
// import UsersClient from '../views/users_client/users_client';
import RegisterIndex from '../views/login/register/register_index';
import IndexForgetPass from '../views/login/forget_pass/index_forgetPass';


const AppRouter = ({openTour, setOpenTour}) => {
    return(
    <Routes>
        {/* <Route path='/mis_usuarios' element={<UsersClient />}></Route> */}
        {/* <Route path='/documentos' element={<Documents />}></Route>
        <Route path='/paquetes' element={<Payments />}></Route>
        <Route path='/paquetes/compra' element={<Purchase />}></Route> */}
        <Route path='/cliente_perfil' element={<Profile />}></Route>
        <Route path='/ticket_info' element={<TicketsInfo />}></Route>
        <Route path='/tickets_tabla' element={<TablaTicket />}></Route>
        <Route path='/ticketsAdministracion' element={<Tickets openTour={openTour} setOpenTour={setOpenTour}/>}></Route>
        <Route path='/registro' element={<RegisterIndex />}></Route>
        <Route path='/recuperar_password' element={<IndexForgetPass />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/*' element={<Login />}></Route>
    </Routes>
    )
}


export default AppRouter;