import React from "react";
import {Modal, Col, Row,} from 'antd';
import { Viewer, Worker, SpecialZoomLevel} from "@react-pdf-viewer/core";
import { REACT_APP_API_HOST } from "../../../../../../env";
import './styles.css'



const Visualizador = ({url, mime, isOpen, setOpen, name}) => {

    return(
        <Modal  open={isOpen} closable={false} footer={null} style={{overflow:'hidden'}} >
            <Row>
                <Col span={24} style={{display:'flex', justifyContent:'flex-end'}}>
                    <span onClick={()=>setOpen(false)} className='closeModalPass'>x</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}  style={{display:'flex', justifyContent:'center'}}>
                    <span className='titleModalMss'>Archivos Adjuntos del Ticket</span>
                </Col>
            </Row>
            {
               ( mime==='image/png' ||  mime==='image/jpeg' || mime==='image/jpg')&& (
                    <Row>
                        <Col span={24}>
                           <img alt="img recurso" src={`${REACT_APP_API_HOST}/static/${url}`} style={{width:'100%'}}/>
                        </Col>
                    </Row>
                )
            }
            {
                mime==='application/pdf' && (
                    <div
                            style={{
                                border: 'none',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '500px',
                            }}
                        >   
                            <Row>
                                <Col span={24} style={{display:'flex', justifyContent:'center'}}>
                                <a href={`${REACT_APP_API_HOST}/static/${url}`} target="_blank" rel="noopener noreferrer" className='aviso'>Abrir en el navegado</a>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    overflowY:'scroll',
                                }}
                            >
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                                    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                                        <Viewer fileUrl={`${REACT_APP_API_HOST}/static/${url}`} defaultScale={SpecialZoomLevel.PageFit} />
                                    </div>
                                </Worker>
                            </div>
                    </div>
                )
            }
            {
                (mime==='application/vnd.ms-excel' || mime==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && (
                    <div
                            style={{
                                border: 'none',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '500px',
                            }}
                        >   
                            <Row>
                                <Col span={24} style={{display:'flex', justifyContent:'center'}}>
                                    <a href={`${REACT_APP_API_HOST}/static/${url}`} download={mime === 'application/vnd.ms-excel' ? `${name}.xls` : `${name}.xlsx`} className='aviso'>Descargar archivo excel</a>
                                </Col>
                            </Row>
                    </div>
                )
            }
        </Modal>
    )

}

export default Visualizador;