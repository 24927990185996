import React from "react";
import {Col, Row, Avatar } from "antd";
import { motion } from "framer-motion";
import { UserOutlined } from "@ant-design/icons";
import imgBg from '../../../../assets/imgs/imgBgProfile.jpg'
import fixtures from "../../fixtures";
import './styles.css'

const CardImg = () => {
    
    let user = JSON.parse(sessionStorage.getItem('userKronox'));

        //PRIMER LETRA MAYUSCULA CADENA
    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    
    return(
        <>
        {user !== undefined && user !== null && ( 
            <motion.div className="cardImgProfile" 
            initial={fixtures().cardInitial}
            animate={fixtures().cardAnimated}
            transition={fixtures().cardTransition}
            >
                <Row>
                    <Col span={24}>
                        <img src={imgBg} alt="imagen default background"  className="imgBack"/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Avatar size={100} icon={<UserOutlined />} style={{marginTop:-50, backgroundColor:'#7e8383'}} />
                    </Col>
                    <Col span={24} style={{padding:'5% 0%', marginBottom:50}}>
                        <span className="nameCardUser">
                            {`${capitalizeFirstLetter(user.usu_nombres)} ${capitalizeFirstLetter(user.usu_apaterno)} ${capitalizeFirstLetter(user.usu_amaterno)}`}
                        </span>
                    </Col>
                </Row>
    
            </motion.div>
        )}
        </>
    )
}

export default CardImg;
