import React, {useEffect, useState, useRef} from "react";
import { Layout, Col, Row, Tour, message, Form, Checkbox, ConfigProvider } from "antd";
import CreateTicket from "./components/newTicket/createTicket";
import CardOrder from "./components/cardOrder/cardOrder";
import ModalPassword from "../../components/modalPassword/modalPassword";
import ModalMessage from "../../components/modalMessage/modalMessage";
import Chat from "./components/chat/chat";
import TicketCard from "./components/ticketCard/ticketCard";
import FooterLayout from "../../components/footer/footer";
import { GetTickets } from "../../hooks/api/tickets/getTickets";
import { GetEmpresas } from "../../hooks/api/empresas/getEmpresas";
import { GetLicencias } from "../../hooks/api/licencias/getLicencias";
import { GetNewMessages } from "../../hooks/api/chat/new-messages";
import { GetUserChats } from "../../hooks/api/chat/user-chats";
import { SeenMessage } from "../../hooks/api/chat/seen-message";
import Logo from '../../assets/imgs/logoDefault.png'
import { TicketStatus, TicketIncidencia } from "../../enums/tickets.enum";
import './styles.css'

const { Content } = Layout;


const Tickets = ({openTour, setOpenTour}) => {

    const {getTicketsMutation} = GetTickets();
    const {getEmpresasMutation} = GetEmpresas();
    const {getLicenciasMutation} = GetLicencias();
    const {getNewMessagesMutation} = GetNewMessages();
    const {getUserChatsMutation} = GetUserChats();
    const {seenMessageMutation} = SeenMessage();

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);

    let user = JSON.parse(sessionStorage.getItem('userKronox'));
    let showMessage = sessionStorage.getItem('messageShow')
    let hideGuideAuto = JSON.parse(localStorage.getItem('hideGuideAuto'))
    const [openModal, setOpenModal] = useState (true);
    const [openModalMessage, setOpenModalMessage] = useState (false);
    const [cardTicketData, setCardTicketData] = useState(null);
    const [emitSocket, setEmitSocket] = useState(false)
    const [licenciasOption, setLicenciasOption] = useState([]);
    const [empresasOption, setEmpresasOption] = useState([]);
    const [form] = Form.useForm();
    const [selectEmpresa, setSelectEmpresa] = useState(false);
    const [listLicences, setListLicences] = useState(null)
    const [chatInUse, setChatInUse] = useState(null);
    const [chats, setChats] = useState([]);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [guideCheck, setGuideCheck] = useState(hideGuideAuto)

    const onChange = (e) => {
        setGuideCheck(e.target.checked)
    };

    const steps = [
        {
            title: (<span className="titleTour">Bienvenidos a la plataforma de servicio de Kronox & Kairós</span>),
            description: (
                    <>
                        <Row>
                            <Col span={24}>
                                <span className="textTour">Si tienes un problema los pasos a seguir son los siguientes</span>
                            </Col>
                            <Col span={24} style={{marginTop:15}}>
                                <Checkbox onChange={onChange}  checked={guideCheck} className="textTour">No volver a mostrar</Checkbox>
                            </Col>
                        </Row>
                    </>
                ),
            target: null,
            nextButtonProps:{
                
            }
        },
        {
            title: (<span className="titleTour">Selecciona tu empresa</span>),
            description: (
                <>
                    <Row>
                        <Col span={24}>
                            <span className="textTour">Si cuentas con varias empresas selecciona tu empresa ya que el nivel de respuesta se podría ver
                            afectado dependiendo de si la empresa cuenta con póliza de servicio o algún proyecto en curso</span>
                        </Col>
                        <Col span={24} style={{marginTop:15}}>
                            <Checkbox onChange={onChange} checked={guideCheck} className="textTour">No volver a mostrar</Checkbox>
                        </Col>
                    </Row>
                </>
                ),
            target: () => ref1.current,
        },
        {
            title: (<span className="titleTour">Selecciona tu Licencia</span>),
            description: (
                <>
                    <Row>
                        <Col span={24}>
                            <span className="textTour">Para poder ofrecerte un mejor servicio es importante seleccionar el sistema, ya que dependiendo
                            del sistema son los asesores que se asignaran.</span>
                        </Col>
                        <Col span={24} style={{marginTop:15}}>
                            <Checkbox onChange={onChange} checked={guideCheck} className="textTour">No volver a mostrar</Checkbox>
                        </Col>
                    </Row>
                </>
                ),
            target: () => ref2.current,
        },
        {
            title: (<span className="titleTour">Problemática</span>),
            description: (
                <>
                    <Row>
                        <Col span={24}>
                            <span className="textTour">Coméntanos que duda o problema se te esta presentando, es importante este apartado ya que al
                            hacerlo el departamento de soporte pueden tomar medidas que pueden favorecer no sólo el tiempo
                            de espera si no el tiempo de resolución.</span>
                        </Col>
                        <Col span={24} style={{marginTop:15}}>
                            <Checkbox onChange={onChange} checked={guideCheck} className="textTour">No volver a mostrar</Checkbox>
                        </Col>
                    </Row>
                </>
                ),
            target: () => ref3.current,
        },
        {
            title: (<span className="titleTour">Archivo</span>),
            description: (
                <>
                    <Row>
                        <Col span={24}>
                            <span className="textTour">El incluir un archivo o imagen del problema es opcional, pero si lo haces puedes ayudar a nuestro
                            departamento de soporte a ofrecerte un mejor servicio ya que con ella nos puedes facilitar el prever
                            de información valiosa para el diagnostico y la resolución del problema.</span>
                        </Col>
                        <Col span={24} style={{marginTop:15}}>
                            <Checkbox onChange={onChange} checked={guideCheck} className="textTour">No volver a mostrar</Checkbox>
                        </Col>
                    </Row>
                </>
                ),
            target: () => ref4.current,
        },
        {
            title: (<span className="titleTour">Enviar</span>),
            description: (
                <>
                    <Row>
                        <Col span={24}>
                            <span className="textTour">Enviar una vez con todo lo anterior llenado puedes hacer clic en el botón de enviar y tu ticket pasará
                            a la fila de atención</span>
                        </Col>
                        <Col span={24} style={{marginTop:15}}>
                            <Checkbox onChange={onChange} checked={guideCheck} className="textTour">No volver a mostrar</Checkbox>
                        </Col>
                    </Row>
                </>
                ),
            target: () => ref5.current,
        },
        {
            title: (<span className="titleTour">En atención</span>),
            description: (
                <>
                    <Row>
                        <Col span={24}>
                            <span className="textTour">En este apartado puedes ir dando seguimiento a los tickets que se están atendiendo hay que
                            considerar que el promedio de atención por ticket es de 15 minutos.</span>
                        </Col>
                        <Col span={24} style={{marginTop:15}}>
                            <Checkbox onChange={onChange} checked={guideCheck} className="textTour">No volver a mostrar</Checkbox>
                        </Col>
                    </Row>
                </>
                ),
            target: () => ref6.current,
        },
        {
            title: (<span className="titleTour">Siguientes</span>),
            description: (
                <>
                    <Row>
                        <Col span={24}>
                            <span className="textTour">En esta sección se puede apreciar cuales son los siguientes tickets a ser atendidos si el folio de tu
                            ticket se encuentra ahí aparecerá resaltado en rojo.</span>
                        </Col>
                        <Col span={24} style={{marginTop:15}}>
                            <Checkbox onChange={onChange} checked={guideCheck} className="textTour">No volver a mostrar</Checkbox>
                        </Col>
                    </Row>
                </>
            ),
            target: () => ref7.current,
        },
    ]


    useEffect(() => {
        const intervalId = setInterval(() => {
            getChats();
        }, 2000);
        return () => clearInterval(intervalId );
    }, [chatInUse]);

    // useEffect(() => {
    //     const intervalId = setInterval(changeTextTour, 300);
    //     return () => clearInterval(intervalId);
    // }, [openTour || hideGuideAuto === false]);

    const getData = async () => {
        const fetchData = async () => {
            try {
                const empresas = await getEmpresas();
                const ticketsActual = await recoverDataTickets();
                if(empresas.length === 1){
                    form.setFieldsValue({
                        empresa: empresas[0]
                    })
                    setSelectEmpresa(true)
                    const licencias = await getLicencias(empresas[0].value, ticketsActual);
                    setLicenciasOption(licencias)
                    if(licencias.length === 1){
                        form.setFieldsValue({
                            licencia: licencias[0]
                        }) 
                    }
                }
                if(form.getFieldValue('empresa') !== undefined){
                    let emp_id = form.getFieldValue('empresa').value !== undefined ? form.getFieldValue('empresa').value : form.getFieldValue('empresa');
                    const licencias = await getLicencias(emp_id, ticketsActual);
                    setLicenciasOption(licencias)
                    setSelectEmpresa(true)
                }
                setEmpresasOption(empresas)
            } catch (error) {
                console.error(error)
            }
        }
        await fetchData();
    }
    const getChats = () => {
        const fetchData = async () => {
            try {
                const newMessagesData = await newMessages();
                if( newMessagesData ){
                    if(chatInUse && isChatOpen){
                        let new_exist= newMessagesData.find(mss => mss.conv_id === chatInUse.conv_id);
                        if(new_exist !== undefined){
                            seenMessageMutation(new_exist.mens_id,{
                                onSuccess: (data) => {
                                    console.log(data)
                                },
                                onError: (error) => {
        
                                }
                            })
                            const new_message_del = newMessagesData.findIndex(objeto => objeto.mens_id === new_exist.mens_id);
                            if (new_message_del !== -1) {
                                // Utiliza splice para eliminar el objeto en el índice encontrado
                                newMessagesData.splice(new_message_del, 1);
                                console.log("Objeto eliminado:", newMessagesData);
                                } else {
                                console.log("Objeto no encontrado en el array.");
                                }
                        }
                    }
                    if(newMessagesData.length>0){
                        let chatsActives = chats;
                        let chatsData = await userChats(user.usu_id);
                        for (let x = 0; x < newMessagesData.length; x++) {
                            const chat = chatsData.find(chatData => chatData.conv_id === newMessagesData[x].conv_id);
                            chat.user_list = chat.user_list.filter(u => u.usu_id !== user.usu_id)
                            if(!chats.some(cht => cht.user_list[0].usu_id === chat.user_list[0].usu_id)){
                                chatsActives.push(chat)
                                setChats(chatsActives)
                            }
                        }
                        if(newMessagesData.length === 1){
                            setChatInUse(chatsActives[0])
                        }
                        setIsChatOpen(true)
                    }
                }
                
            } catch (error) {
                console.error(error)
            }
        }
        fetchData();
    }

    const newMessages = () => {
        return new Promise((resolve, reject) => {
            getNewMessagesMutation({},{
                onSuccess: (data) => {
                    resolve(data)
                },
                onError: (error) => {
                    reject(error)
                }
            })
        })
    }
    const userChats = (id) => {
        return new Promise((resolve, reject) => {
            getUserChatsMutation( id,{
                onSuccess: (data) => {
                    resolve(data)
                },
                onError: (error) => {
                    reject(error)
                }
            })
        })
    }

    // const changeTextTour = () => {
    //     if(openTour === true){
    //         const buttonsDiv = document?.querySelector('.ant-tour-buttons');
    //         const prevButton = buttonsDiv?.querySelector('.ant-tour-prev-btn');
    //         const nextButton = buttonsDiv?.querySelector('.ant-tour-next-btn');
    //         if(nextButton !== null && nextButton !== undefined){
    //             const spanElement = nextButton?.querySelector('span');
    //             spanElement.textContent = 'Siguiente';
    //         }
    //         if(prevButton !== null && prevButton !== undefined){
    //             const spanElement = prevButton?.querySelector('span');
    //             spanElement.textContent = 'Anterior';
    //         }
            
    //     }
    // }

    const recoverDataTickets = () => {
        return new Promise((resolve, reject) => {
            getTicketsMutation({},
                {
                    onSuccess: (data) => {
                        if(data.length !== 0){
                            const firstFilter = data.filter(item => item.status.stat_id !== 5 && item.status.stat_id !== 6) ;
                            const filterData = firstFilter.filter(item => item.incidencias.every(element => element.tipi_id < 8) || item.status.stat_id === 9);
                            const filterUser = filterData.filter(item =>  item.creador.usu_id === user.usu_id) ;
                            const newData =  filterUser.map((ticket, index) => {
                                return{
                                    key: index,
                                    id: ticket.tick_id,
                                    clave: ticket.tick_clave,
                                    status: ticket.status.stat_nombre,
                                    paso: ticket.incidencias.length === 0 ? 5 : ticket.status.stat_id === TicketStatus.TICKET_SEGUIMIENTO ? 50 : ticket.status.stat_id === TicketStatus.CADUCADO ? 100 : ticket.incidencias.some(item => item.tipi_id === TicketIncidencia.TICKET_CERRADO)  ? 80 : ticket.incidencias.some(item => item.tipi_id === TicketIncidencia.CERRANDO_TICKET) ? 70 : ticket.incidencias.some(item => item.tipi_id > TicketIncidencia.NOTA_RAPIDA && item.tipi_id < TicketIncidencia.CERRANDO_TICKET ) ? 50 : 5,
                                    pasoText: ticket.incidencias.length === 0 ? 'En espera' : ticket.status.stat_id === TicketStatus.TICKET_SEGUIMIENTO ? 'En atención' : ticket.status.stat_id === TicketStatus.CADUCADO ? 'Caducado' : ticket.incidencias.some(item => item.tipi_id === TicketIncidencia.TICKET_CERRADO ) ? 'Esperando encuesta' : ticket.incidencias.some(item => item.tipi_id === TicketIncidencia.CERRANDO_TICKET) ? 'Esperando cierre' : ticket.incidencias.some(item => item.tipi_id > TicketIncidencia.NOTA_RAPIDA && item.tipi_id < TicketIncidencia.CERRANDO_TICKET ) ? 'En atención':'En espera',
                                    logo : Logo,
                                    nota: ticket.incidencias.length > 0 && ticket.incidencias.some(item => item.tipi_id === TicketIncidencia.NOTA_RAPIDA) === true ? ticket.incidencias.reverse().find(item => item.tipi_id === TicketIncidencia.NOTA_RAPIDA).tici_observaciones : null,
                                    atencion:   ticket.incidencias.length === 0  ? false :
                                                ticket.status.stat_id === TicketStatus.TICKET_SEGUIMIENTO || ticket.incidencias[ticket.incidencias.length-1].tipi_id === TicketIncidencia.CERRANDO_TICKET || ticket.incidencias[ticket.incidencias.length-1].tipi_id === TicketIncidencia.TICKET_CERRADO ? true :  
                                                ticket.incidencias.some(item => item.tipi_id === TicketIncidencia.TICKET_CERRADO ) || ticket.status.stat_id === TicketStatus.ESPERA_FABRICANTE || ticket.incidencias.some(item => item.tipi_id === TicketIncidencia.CERRANDO_TICKET) || ticket.status.stat_id === TicketIncidencia.TIEMPO_EXCEDIDO ? false : 
                                                ticket.incidencias.some(item => item.tipi_id > TicketIncidencia.NOTA_RAPIDA && item.tipi_id < TicketIncidencia.CERRANDO_TICKET ) ? true : false,
                                    cierre: ticket.incidencias.length > 0 && ticket.incidencias[ticket.incidencias.length-1].tipi_id === TicketIncidencia.CERRANDO_TICKET ? true : false,
                                    encuesta: ticket.incidencias.length > 0 && ticket.incidencias[ticket.incidencias.length-1].tipi_id === TicketIncidencia.TICKET_CERRADO ? true : false,
                                    licencia: ticket.licencia.sistema,
                                    activo: ticket.status.stat_id === TicketStatus.TICKET_SEGUIMIENTO || ticket.status.stat_id === TicketStatus.ESPERA_CLIENTE ? true : false
                                }
                            })
                            
                            setCardTicketData(newData.reverse())
                            resolve(filterData)
                        }else {
                            resolve(data)
                        }
                    },
                    onError: (error) =>{
                        message.error(error)
                        reject(error)
                    }
            })
        })
    }

    const getEmpresas = () => {
        return new Promise((resolve, reject) => {
            getEmpresasMutation({},
                {
                    onSuccess: (data) => {
                        const empresaSelect = data.map((empresa)=>({
                            value: empresa.emp_id,
                            label: empresa.emp_razonsocial,
                        }))
                        resolve(empresaSelect)
                    },
                    onError: (error) =>{
                        message.error(error)
                        console.error(error)
                        reject(error)
                    }
            })
        })
    }

    const getLicencias = (id, listTicket) => {
        return new Promise((resolve, reject) => {
            getLicenciasMutation(id,
                {
                    onSuccess: (data) => {
                        setListLicences(data)
                        const licenciasSelect = data.map((licencia) => ({
                            value: licencia.usli_id,
                            label: licencia.sistema.sis_nombre,
                            disabled: listTicket.some(ticket => ticket.licencia.sis_id === licencia.sis_id && (ticket.status.stat_id === TicketStatus.TICKET_SEGUIMIENTO || ticket.status.stat_id === TicketStatus.ESPERA_CLIENTE )) ? true : false
                        }));
                        resolve(licenciasSelect)
                    },
                    onError: (error) =>{
                        console.error(error)
                        message.error(error)
                        reject(error)
                    }
            })
        })
    }

    const changeEmpresa = (value) => {
        const fetchData = async () => {
            try {
                
                    setSelectEmpresa(false)
                    form.setFieldsValue({
                        licencia: undefined
                    })
                    setLicenciasOption([])
                    const ticketsActual = await recoverDataTickets();
                    const licencias = await getLicencias(value, ticketsActual);
                    setSelectEmpresa(true)
                    setLicenciasOption(licencias)
                    if(licencias.length === 1){
                        form.setFieldsValue({
                            licencia: licencias[0]
                        })
                    }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }

    const closeChat = () => {
        let chat = null
        setIsChatOpen(false)
        setChatInUse(chat);
        setChats([])
    }

    const closeGuide = () => {
        setOpenTour(false)
        localStorage.setItem('hideGuideAuto', guideCheck)
    }

    useEffect(() => {
        const token = sessionStorage.getItem('tokenKronox');
        if (!token) {
            window.location.href = '/login';
        } else{
            getData()
            newMessages();
        }
    }, []);
   
    useEffect(() => {
        const intervalData = setInterval(async ()=>{
            await getData();
        }, 2000);
        return () => clearInterval(intervalData);
    }, []);

    return(
        <>
            <Content style={{paddingBottom:60}}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="colCardsTickets">
                        <CreateTicket   ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} ref5={ref5} setEmitSocket={setEmitSocket} cardTicketData={cardTicketData}
                                        empresasOption={empresasOption} licenciasOption={licenciasOption} onChangeEmpresa={changeEmpresa} getData={getData} form={form}
                                        selectEmpresa={selectEmpresa} listLicences={listLicences}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="colCardsTickets">
                        <Row>
                            <Col span={24}>
                                <CardOrder ref6={ref6} ref7={ref7} emitSocket={emitSocket} setEmitSocket={setEmitSocket}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop:20}}>
                            <Col span={24}>
                                {cardTicketData !== null && cardTicketData.length > 0 ?
                                cardTicketData.map((ticket, index) => (
                                    <TicketCard key={index} id={ticket.id} clave={ticket.clave} status={ticket.status} 
                                                paso={ticket.paso} pasoText={ticket.pasoText} img={ticket.logo} 
                                                nota={ticket.nota} atencion={ticket.atencion} cierre={ticket.cierre} 
                                                encuesta={ticket.encuesta} sistema={ticket.licencia} chatId={ticket.idConversacion}
                                                activo={ticket.activo}/>
                                )): null
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content> 
            
            {/* {openModal === false && openModalMessage && showMessage !== 'true' &&<ModalMessage  open={openModalMessage} setOpenMessage={setOpenModalMessage}/>} */}
            { (user && user.usu_cambiopass === false) || ( user && user.usu_cambiandopass === true) ? (<ModalPassword setOpen={setOpenModal} setOpenMessage={setOpenModalMessage}/>): null}
            <Chat   key={'chat'} isModalOpen={isChatOpen} chatInUse={chatInUse} 
                    setChatInUse={setChatInUse} chatList={chats} closeChat={closeChat}/>
            <Tour open={openModal === false && openTour} onClose={closeGuide} steps={steps}/>
            <FooterLayout/>
            
        </>   
    )
}


export default Tickets;