import img_electron  from '../assets/imgs/img_card/electron.png'
import img_maestro  from '../assets/imgs/img_card/maestro.svg'
import img_dankort  from '../assets/imgs/img_card/dankort.svg'
import img_union from '../assets/imgs/img_card/unionpay.svg'
import img_visa  from '../assets/imgs/img_card/visa.png'
import img_mc  from '../assets/imgs/img_card/mastercard.svg'
import img_amex  from '../assets/imgs/img_card/amex.png'
import img_diners  from '../assets/imgs/img_card/diners.png'
import img_discover  from '../assets/imgs/img_card/discover.svg'
import img_jcb  from '../assets/imgs/img_card/jcb.svg'

export const TypeCard = Object.freeze({
    ELECTRON: {id:1, img: img_electron },
    MAESTRO: {id:2, img: img_maestro },
    DANKORT: {id:3, img: img_dankort },
    UNIONPAY: {id:4, img: img_union },
    VISA: {id:5, img: img_visa },
    MASTERCARD: {id:6, img: img_mc },
    AMEX: {id:7, img: img_amex },
    DINERS: {id:8, img: img_diners},
    DISCOVER: {id:9, img: img_discover },
    JCB: {id:10, img: img_jcb},
})