const colums = [
    {
        title: 'Folio',
        dataIndex: 'folio',
        key: 'folio',
        sorter: (a, b) => a.folio.localeCompare(b.folio),
    },
    {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
        sorter: (a, b) => a.fecha.localeCompare(b.fecha),
    },
    {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
        title: 'Sistema',
        dataIndex: 'system',
        key: 'system',
        sorter: (a, b) => a.system.localeCompare(b.system),
    },
    {
        title: 'Problematica',
        dataIndex: 'problem',
        key: 'problem',
        sorter: (a, b) => a.problem.localeCompare(b.problem),
    },
    {
        title: 'Creo',
        dataIndex: 'do',
        key: 'do',
        sorter: (a, b) => a.do.localeCompare(b.do),
    }
    ,
    {
        title: 'Asesor',
        dataIndex: 'asesor',
        key: 'asesor',
        sorter: (a, b) => a.asesor.localeCompare(b.asesor),
    }
]
const cardInitial = { opacity: 0, scale: 0.5 };
const cardAnimated = { opacity: 1, scale: 1 };
const cardTransition = {duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01]};

const fixtures = () => {
    let fixture = {};
   
    fixture = {
        colums,
        cardInitial,
        cardAnimated,
        cardTransition
    };
  
    return fixture;
  };
  
  export default fixtures;