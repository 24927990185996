import React from "react";
import { ChatItem } from "react-chat-elements";
import { IMAGES } from "../../../../assets/imgs";
import { chatListStyles } from "./styles";
import { createUseStyles } from "react-jss";
import { Row, Typography, Col } from "antd";

const useStyles = createUseStyles(chatListStyles);

const ChatList = ({ onSelectChatItem, chats }) => {

    const styles = useStyles();
    const apiResources = 'http://192.168.100.22:3001/api/static/';

    const handleClickChatItem = (chat) => {
        onSelectChatItem(chat);
    }

    return (
        <Row>
            <Col span={24} style={{padding:8, borderBottom: '1px solid #E0E0E0'}}>
                <Typography.Title level={5} style={{ margin: 0, color:'#44adab', fontWeight:600, fontFamily:'Lato, sans-serif', fontSize:20 }}>
                    Chats con Asesores
                </Typography.Title>
            </Col>

            {chats.map(chat =>
                (
                    <Col key={chat.conv_id} span={24} style={{padding: '20px 0px', borderBottom: '1px solid #ddd'}}>
                        <ChatItem
                            key={chat.conv_id}
                            id={chat.conv_id}
                            avatar={chat?.photo ? `${apiResources}/${chat.photo}` : IMAGES.GENERIC_USER}
                            alt={`${chat.user_list[0].usu_nombres} ${chat.user_list[0].usu_apaterno}`}
                            title={`${chat.user_list[0].usu_nombres} ${chat.user_list[0].usu_apaterno}`}
                            unread={0}
                            className={styles.chatItem}
                            onClick={() => handleClickChatItem(chat)}
                        />
                    </Col>
                )
            )}

        </Row>
        // <div className={styles.container}>
        //     <div
        //         style={{
        //             padding: 10,
        //             borderBottom: "1px solid #E0E0E0",
        //         }}
        //     >
        //         <Typography.Title level={5} style={{ margin: 0 }}>
        //             Chats
        //         </Typography.Title>
        //     </div>
        //     <div
        //         style={{
        //             overflowY: "auto",
        //             maxHeight: "calc(100vh - 100px)",
        //         }}
        //     >
        //         {
        //             chats.map(chat =>
        //                 <ChatItem
        //                     key={chat.conv_id}
        //                     id={chat.conv_id}
        //                     avatar={chat?.photo ? `${apiResources}/${chat.photo}` : IMAGES.GENERIC_USER}
        //                     alt={`${chat.user_list[0].usu_nombres} ${chat.user_list[0].usu_apaterno} ${chat.user_list[0].usu_amaterno}`}
        //                     title={`${chat.user_list[0].usu_nombres} ${chat.user_list[0].usu_apaterno} ${chat.user_list[0].usu_amaterno}`}
        //                     // subtitle={chat.lastMessage}
        //                     // date={new Date()}
        //                     unread={0}
        //                     className={styles.chatItem}
        //                     onClick={() => handleClickChatItem(chat)}
        //                 />
        //             )
        //         }
        //     </div>
        // </div>
    );
}

export default ChatList;