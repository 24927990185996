import React, {useState, useEffect} from 'react';
import { Layout, Row, Col } from 'antd';
import './styles.css'
import IngressCard from './ingress/ingress';


const Login = () => {

    const [numerCard, setNumerCard] = useState(1)

    useEffect(() => {
        operacionCondicional()
    }, []);


    async function operacionCondicional() {
        try {
            const token = sessionStorage.getItem('tokenKronox');
            if (!token) {
            const resultado = await promiseRedirect();
            } else {
            throw new Error('Existe una sesion activa');
            }
        } catch (error) {
            window.location.href = '/ticketsAdministracion';
        }
    }

    const promiseRedirect = () => {
        return new Promise((resolve) => {
            const token = sessionStorage.getItem('tokenKronox');
            if (token) {
                window.location.href = '/ticketsAdministracion';
            }
            const id_paquete = sessionStorage.getItem('id_paquete');
            if (id_paquete) {
                sessionStorage.removeItem('id_paquete')
            }
            resolve(true)
        })
    };
    

    const changeCard = (id) => {
        setNumerCard(id)
    }


    return(
        <Layout className='layoutLogin'>
            <Row style={{width:'100%'}}>
                <Col xs={{ span:18, offset:3 }} sm={{ span:15, offset:5 }} md={{ span:14, offset:5 }} lg={{ span:7, offset:9 }}>
                    <IngressCard  goRegister={changeCard} />
                </Col>
            </Row>
        </Layout>
    )
}


export default Login;