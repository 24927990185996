import useApi from "../useApiRequest";
import { useMutation } from "react-query";

export const RegisterUser = () => {
    const api = useApi();

    /**
     * 
     * @param {MutatingRegisterUser} params el tipo {@link MutatingRegisterUser} se utilizará para las acciones create y update
     * returns 
    */
    const mutate = async (params) => {
            const data  = await api.noSessionPost(`/clientes/registry`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
    };

    const mutation = useMutation(mutate);

    return {
        mutateRegisterUser: mutation.mutate,
    };
};