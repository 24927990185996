import React, {useState} from "react";
import { Row, Col } from "antd";
import { motion } from "framer-motion";
import ModalPassword from "../../../../../../components/modalPassword/modalPassword";
import fixtures from "../../../../fixtures";
import './styles.css'

const TabPasswrd = () => {

    const [openModal, setOpenModal] = useState (false);

    return(
        <Row className="rowTabPsswrd">
            <Col span={24} className="colTextPsswrd">
                <span className="textTabPsswrd">Si deseas cambiar tu contraseña</span>
            </Col>
            <Col span={24} className="colBtnPsswrd">
                <motion.button
                    whileHover={fixtures().hoverbtnLogin} whileTap={fixtures().tapbtnLogin}
                    type='submit'  className='btn-Primary btnChangePsswrd'
                    onClick={()=>{setOpenModal(!openModal)}}>
                        Haz click aqui
                </motion.button>
            </Col>
            {openModal ? (<ModalPassword setOpen={setOpenModal}/>) : null}
        </Row>
    )
}

export default TabPasswrd;